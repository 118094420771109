/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
import * as msRest from '@azure/ms-rest-js';
const packageName = '';
const packageVersion = '1.0.0-beta.1';
export class RefundApiContext extends msRest.ServiceClient {
    /**
     * Initializes a new instance of the RefundApiContext class.
     * @param [options] The parameter options
     */
    constructor(options) {
        if (!options) {
            options = {};
        }
        if (!options.userAgent) {
            const defaultUserAgent = msRest.getDefaultUserAgentValue();
            options.userAgent = `${packageName}/${packageVersion} ${defaultUserAgent}`;
        }
        super(undefined, options);
        this.baseUri = options.baseUri || this.baseUri || 'http://localhost';
        this.requestContentType = 'application/json; charset=utf-8';
    }
}
