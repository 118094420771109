import * as React from 'react';
import { translate } from 'react-i18next';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import { LoaderButton } from '~/common/components';
import './FormFooter.scss';
export const FormFooterComponent = (props) => {
    return (React.createElement("div", { id: "form-footer" },
        React.createElement("div", { className: "container md-grid" },
            React.createElement("div", { className: "md-cell md-cell--12" },
                React.createElement("div", { className: "form-footer--contents" },
                    props.children,
                    React.createElement("div", { className: "form-footer--button-wrapper form-footer--button-right" },
                        React.createElement("div", { className: `md-btn--outline ${props.showBackButton ? '' : 'back-btn-hidden'}` },
                            React.createElement("a", { href: "javascript:;", id: "btnBackPage", onClick: props.handleGoBack },
                                React.createElement(FontIcon, null, "arrow_back"),
                                React.createElement("label", null, props.backButtonLabel || 'Back'))),
                        React.createElement(LoaderButton, { id: "btnSubmitPage", className: "md-btn--outline md-btn--outline-tertiary", disabled: props.disabled, pending: props.validating, type: "button", onClick: props.handleSubmit },
                            React.createElement("label", null, props.submitButtonLabel),
                            React.createElement(FontIcon, null, "arrow_forward"))))))));
};
export const FormFooter = translate(['base'])(FormFooterComponent);
export default FormFooter;
