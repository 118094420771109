import * as React from 'react';
import { translate } from 'react-i18next';
import { Question, MDRadioButton } from '~/common/components';
import { modelPath } from '~/common/state';
export const OwnedByBusinessOrTrustComponent = (props) => {
    const model = `${modelPath}.ownedByBusinessOrTrust`;
    return (React.createElement(Question, { id: "questionOwnedByBusinessOrTrust", model: model, translation: "quote:ownedByBusinessOrTrust" },
        React.createElement(MDRadioButton, { id: "ownedByBusinessOrTrust", model: model, options: [{ value: true, label: props.t('button.yes') }, { value: false, label: props.t('button.no') }] })));
};
export const OwnedByBusinessOrTrust = translate(['base'])(OwnedByBusinessOrTrustComponent);
export default OwnedByBusinessOrTrust;
