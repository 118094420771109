import { combineReducers } from 'redux';
import { combineForms } from 'react-redux-form';
import { createFlagsReducer } from 'flag';
// common
import { reducer as commonReducer } from '~/common/state';
export const rootReducer = combineReducers({
    flags: createFlagsReducer({}),
    myForms: combineForms({
        common: commonReducer
    }, 'myForms')
});
