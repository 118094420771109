import { __rest } from "tslib";
import * as React from 'react';
import { Control } from 'react-redux-form';
import { translate } from 'react-i18next';
import { TooltipIcon } from '~/common/components';
import { QuestionTick } from './QuestionTick';
import { isEmpty } from '~/common/utilities';
import './Question.scss';
export const QuestionTickContainer = ({ show, error, noTick }) => noTick ? null : (React.createElement(React.Fragment, null,
    React.createElement(QuestionTick, { show: show, error: error }),
    show && React.createElement("span", { className: "question-vertical-line" })));
export const QuestionComponent = (_a) => {
    var { id, model, translation, translationTitleData, translationDescriptionData, overrideTranslationDescriptionKey, subQuestion, fullWidth, noTick, t, overrideId, forceShowError, hideTooltip, hideQuestion, className } = _a, rest = __rest(_a, ["id", "model", "translation", "translationTitleData", "translationDescriptionData", "overrideTranslationDescriptionKey", "subQuestion", "fullWidth", "noTick", "t", "overrideId", "forceShowError", "hideTooltip", "hideQuestion", "className"]);
    // @ts-ignore
    const title = t(`${translation}.title`, translationTitleData);
    const description = t(`${translation}.${overrideTranslationDescriptionKey || 'description'}`, translationDescriptionData);
    const tooltip = t(`${translation}.tooltip`);
    const tooltipList = t(`${translation}.tooltipList`);
    let tooltipListJSX = null;
    if (tooltip && tooltipList && tooltipList.indexOf('\n') > -1) {
        tooltipListJSX = (React.createElement("ul", null, tooltipList.split('\n').map((text, i) => {
            return React.createElement("li", { key: 'ownerListItem' + i }, text);
        })));
    }
    const questionCls = 'question-' + model.replace(/\./g, '-').replace(/[\[\]]+/g, '');
    const tooltipContainerCls = '.' + questionCls + ' .tooltip-container';
    const innerId = overrideId ? 'question-' + overrideId : questionCls;
    return (React.createElement("fieldset", { id: id, className: 'question ' +
            (description ? 'question--description ' : '') +
            (subQuestion ? 'question--subquestion ' : '') +
            (fullWidth ? 'question--fullwidth ' : '') +
            (tooltip ? 'question--tooltip ' : '') +
            (hideQuestion ? 'question--hidden ' : '') +
            (className ? className + ' ' : '') +
            questionCls },
        React.createElement(Control, { model: model, withField: true, mapProps: {
                show: (props) => {
                    const { errors, value } = props.fieldValue;
                    let hasError = errors === true;
                    for (const k in errors) {
                        if (errors[k] === true) {
                            hasError = true;
                        }
                    }
                    return !forceShowError && isEmpty(value) === false && hasError === false;
                },
                error: (props) => {
                    const { errors, value } = props.fieldValue;
                    let hasError = false;
                    for (const k in errors) {
                        if (errors[k] === true) {
                            hasError = true;
                        }
                    }
                    return forceShowError || (isEmpty(value) === false && hasError === true);
                },
                noTick: () => noTick,
            }, component: QuestionTickContainer }),
        !hideQuestion && (React.createElement("div", { className: "question-title" },
            React.createElement("h3", { id: innerId + '-heading', dangerouslySetInnerHTML: { __html: title }, className: "heading5" }),
            !hideTooltip && tooltip && (React.createElement(TooltipIcon, { parentClass: tooltipContainerCls, label: tooltip, extraContent: tooltipListJSX, id: innerId + '-tooltip' })))),
        React.createElement("div", { className: "tooltip-container" }),
        description && (React.createElement("p", { id: innerId + '-description', className: "question-subtext", dangerouslySetInnerHTML: { __html: description } })),
        React.createElement("legend", null,
            title,
            ". ",
            description ? description : ''),
        rest.children));
};
export const Question = translate()(QuestionComponent);
