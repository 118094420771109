import * as React from 'react';
import Tippy from '@tippy.js/react';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import './TooltipIcon.scss';
export const TooltipIcon = ({ label, id, extraContent, parentClass, placement = 'right', showArrow }) => {
    return (React.createElement("div", { className: "tooltip-icon" },
        React.createElement("div", null,
            React.createElement(Tippy, { arrow: showArrow, arrowType: "sharp", placement: placement, theme: "light", interactive: true, autoFocus: false, delay: 100, performance: true, flip: false, trigger: 'click', multiple: true, content: React.createElement(React.Fragment, null,
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: label } }),
                    extraContent,
                    React.createElement("a", { href: "javascript:;", className: "tooltip-icon-close", onClick: () => {
                            document.querySelector('body').click();
                        } },
                        React.createElement(FontIcon, null, "close"))), appendTo: () => {
                    if (parentClass) {
                        return document.querySelector(parentClass);
                    }
                    else {
                        return document.body;
                    }
                } },
                React.createElement("a", { href: "javascript:;", "aria-label": label, id: id },
                    React.createElement(FontIcon, null, "information"))))));
};
export default TooltipIcon;
