/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */
export const ProblemDetails = {
    serializedName: 'ProblemDetails',
    type: {
        name: 'Composite',
        className: 'ProblemDetails',
        modelProperties: {
            type: {
                serializedName: 'type',
                type: {
                    name: 'String'
                }
            },
            title: {
                serializedName: 'title',
                type: {
                    name: 'String'
                }
            },
            status: {
                nullable: true,
                serializedName: 'status',
                type: {
                    name: 'Number'
                }
            },
            detail: {
                serializedName: 'detail',
                type: {
                    name: 'String'
                }
            },
            instance: {
                serializedName: 'instance',
                type: {
                    name: 'String'
                }
            }
        },
        additionalProperties: {
            type: {
                name: 'Object'
            }
        }
    }
};
export const SubmitBankAccountDetailsRequest = {
    serializedName: 'SubmitBankAccountDetailsRequest',
    type: {
        name: 'Composite',
        className: 'SubmitBankAccountDetailsRequest',
        modelProperties: {
            refundCode: {
                required: true,
                serializedName: 'refundCode',
                type: {
                    name: 'String'
                }
            },
            bankAccountName: {
                required: true,
                serializedName: 'bankAccountName',
                type: {
                    name: 'String'
                }
            },
            bankAccountBank: {
                required: true,
                serializedName: 'bankAccountBank',
                type: {
                    name: 'String'
                }
            },
            bankAccountBranch: {
                required: true,
                serializedName: 'bankAccountBranch',
                type: {
                    name: 'String'
                }
            },
            bankAccountNumber: {
                required: true,
                serializedName: 'bankAccountNumber',
                type: {
                    name: 'String'
                }
            },
            bankAccountSuffix: {
                required: true,
                serializedName: 'bankAccountSuffix',
                type: {
                    name: 'String'
                }
            },
            customerFirstName: {
                serializedName: 'customerFirstName',
                type: {
                    name: 'String'
                }
            },
            customerLastName: {
                serializedName: 'customerLastName',
                type: {
                    name: 'String'
                }
            },
            companyName: {
                serializedName: 'companyName',
                type: {
                    name: 'String'
                }
            },
            preferredEmailAddress: {
                serializedName: 'preferredEmailAddress',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
export const SubmitBankAccountDetailsResponse = {
    serializedName: 'SubmitBankAccountDetailsResponse',
    type: {
        name: 'Composite',
        className: 'SubmitBankAccountDetailsResponse',
        modelProperties: {
            returnCode: {
                serializedName: 'returnCode',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
