import * as React from 'react';
import { actions, Control } from 'react-redux-form';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import { ToggleButton } from '~/common/components';
import './MDRadioButton.scss';
export class MDRadioButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boxOpen: false
        };
        this.toggleBox = (e) => {
            e.preventDefault();
            this.setState({ boxOpen: !this.state.boxOpen });
            // boxOpen = true;
        };
        this.changeAndSubmit = (mdl, value) => (dispatch) => {
            if (!value) {
                value = false;
            }
            dispatch(actions.change(mdl, value));
            dispatch(actions.setValidity(mdl, true));
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        };
        if (props.boxOpenByDefault) {
            this.state.boxOpen = true;
        }
    }
    renderExpander() {
        const { boxOpen } = this.state;
        const { expandText, collapseText } = this.props;
        const text = boxOpen ? collapseText : expandText;
        const icon = boxOpen ? 'expand_less' : 'expand_more';
        return (React.createElement("div", { className: "md-radiobutton--box-link" },
            React.createElement("a", { id: "md-radiobutton--expand-link", href: "#", className: "expand-link", onClick: this.toggleBox },
                React.createElement("span", null, text),
                React.createElement(FontIcon, { className: "expander" }, icon))));
    }
    render() {
        const { boxOpen } = this.state;
        const { id, triple, boxed, boxedAdditionalText, className, model, tick, ariaDescribedBy, options, validators, disabled, leftAlignText } = this.props;
        let classNames = 'md-radiobutton-container ';
        if (triple) {
            classNames += 'md-radiobutton-container--triple ';
        }
        if (boxed) {
            classNames += 'md-radiobutton-container--boxed ';
        }
        if (boxOpen) {
            classNames += 'md-radiobutton-container--boxed--open ';
        }
        if (leftAlignText) {
            classNames += 'md-radiobutton-container--left-align ';
        }
        if (className) {
            classNames += className;
        }
        return (React.createElement("div", { id: `${id}-container`, className: classNames },
            options.map((option, index) => {
                return (React.createElement(Control.radio, { key: `${id}-${index}`, model: model, changeAction: this.changeAndSubmit, validators: validators, component: CustomButtonControl, mapProps: {
                        selected: (props) => props.modelValue === option.value
                    }, controlProps: Object.assign({ id: id + '-' + index, index, tick: tick, ariaDescribedBy: ariaDescribedBy, boxed: boxed, boxOpen: boxOpen, disabled: disabled }, option) }));
            }),
            boxedAdditionalText ? (React.createElement("div", { className: "md-radiobutton--boxed-additional-text" }, boxedAdditionalText)) : null,
            boxed && this.renderExpander()));
    }
}
class CustomButtonControl extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            mobileItemExpanded: false
        };
        this.toggleMobileExpander = (e) => {
            e.preventDefault();
            this.setState({ mobileItemExpanded: !this.state.mobileItemExpanded });
        };
    }
    render() {
        const props = this.props;
        const { mobileItemExpanded } = this.state;
        if (props.boxed) {
            return (React.createElement("div", { className: `md-radiobutton--wrapper ${props.selected ? 'md-radiobutton--wrapper--active' : ''}` },
                React.createElement("div", { className: "md-radiobutton--box" },
                    this.renderButton(),
                    props.aboveDescription && React.createElement("div", { className: "above-description" }, props.aboveDescription),
                    props.description && React.createElement("p", null, props.description),
                    this.renderMobileExpander()),
                (mobileItemExpanded || props.boxOpen) && (React.createElement("div", { className: `md-radiobutton--box--children ${mobileItemExpanded ? 'md-radiobutton--box--children--open' : ''}` },
                    props.children,
                    React.createElement("p", { className: "link-with-icon mobile-only" },
                        React.createElement("a", { id: `${props.id}-link-with-icon-link`, href: props.linkLocation }, props.linkText),
                        React.createElement(FontIcon, { className: "save" }, "save_alt"))))));
        }
        else {
            return (React.createElement("div", { className: `md-radiobutton--wrapper ${props.selected ? 'md-radiobutton--wrapper--active' : ''} ${props.description ? 'md-radiobutton--wrapper--withdescription' : ''}` },
                this.renderButton(),
                props.description && React.createElement("p", null, props.description),
                React.createElement("div", { className: "test" }, props.children),
                props.linkText && (React.createElement("p", { className: "link-with-icon" },
                    React.createElement("a", { id: `${props.id}-link-with-icon-link`, href: props.linkLocation }, props.linkText),
                    React.createElement(FontIcon, { className: "save" }, "save_alt")))));
        }
    }
    renderButton() {
        const props = this.props;
        return (React.createElement(ToggleButton, { id: 'btn' + props.id, label: props.label, extraLabel: props.extraLabel, value: props.value, selected: props.selected, inlineIcon: props.selected && props.tick ? 'done' : '', icon: props.icon, disabled: props.disabled, "aria-describedby": props.ariaDescribedBy, onClick: (val) => {
                props.onChange(val);
            } }));
    }
    renderMobileExpander() {
        const { mobileItemExpanded } = this.state;
        const text = mobileItemExpanded ? 'Hide' : 'Find out more';
        const icon = mobileItemExpanded ? 'expand_less' : 'expand_more';
        return (React.createElement("div", { className: "md-radiobutton--box-mobile-content" }, this.props.linkText && (React.createElement(React.Fragment, null,
            React.createElement("a", { href: "#", className: "expand-link", onClick: this.toggleMobileExpander },
                text,
                React.createElement(FontIcon, { className: "expander" }, icon))))));
    }
}
