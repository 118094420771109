import { __rest } from "tslib";
import * as React from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import './ClosableDialogContainer.scss';
export class ClosableDialogContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            modalMode: false
        };
    }
    render() {
        const _a = this.props, { ariaLabel } = _a, rest = __rest(_a, ["ariaLabel"]);
        const { modalMode } = this.state;
        return (React.createElement(DialogContainer, Object.assign({}, rest, { "aria-label": ariaLabel, paddedContent: false, focusOnMount: true, closeOnEsc: true, 
            // lock dialog in modal mode if mousedown inside dialog
            // prevent accidental close when drag mouse cursor outside
            onMouseDown: () => this.setState({ modalMode: true }), onMouseUp: () => this.setState({ modalMode: false }), modal: modalMode }),
            React.createElement("div", { className: 'closable-dialog-container' },
                React.createElement("a", { href: "javascript:void(0)", onClick: () => rest.onHide(), className: 'closable-dialog-container--close-button' },
                    React.createElement(FontIcon, null, "close")),
                rest.children)));
    }
}
