/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
export const ValidateBankAccountResponse = {
    serializedName: 'ValidateBankAccountResponse',
    type: {
        name: 'Composite',
        className: 'ValidateBankAccountResponse',
        modelProperties: {
            valid: {
                serializedName: 'valid',
                type: {
                    name: 'Boolean'
                }
            },
            validationErrorMessage: {
                serializedName: 'validationErrorMessage',
                type: {
                    name: 'String'
                }
            },
            bankName: {
                readOnly: true,
                serializedName: 'bankName',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
export const BankAccountChangeRequest = {
    serializedName: 'BankAccountChangeRequest',
    type: {
        name: 'Composite',
        className: 'BankAccountChangeRequest',
        modelProperties: {
            policyNumbers: {
                required: true,
                serializedName: 'policyNumbers',
                type: {
                    name: 'Sequence',
                    element: {
                        serializedName: 'stringElementType',
                        type: {
                            name: 'String'
                        }
                    }
                }
            },
            bankName: {
                required: true,
                serializedName: 'bankName',
                type: {
                    name: 'String'
                }
            },
            bankAccountName: {
                required: true,
                serializedName: 'bankAccountName',
                constraints: {
                    Pattern: /[a-zA-Z ]+/
                },
                type: {
                    name: 'String'
                }
            },
            bankAccountNumber: {
                required: true,
                serializedName: 'bankAccountNumber',
                constraints: {
                    Pattern: /[0-9]{16}/
                },
                type: {
                    name: 'String'
                }
            },
            authorisationSetupDirectDebitAuthorityWithoutSigningFormInd: {
                required: true,
                serializedName: 'authorisationSetupDirectDebitAuthorityWithoutSigningFormInd',
                type: {
                    name: 'Boolean'
                }
            },
            authorisationCancelledDirectDebitAuthorityWithTowerInsuranceInd: {
                required: true,
                serializedName: 'authorisationCancelledDirectDebitAuthorityWithTowerInsuranceInd',
                type: {
                    name: 'Boolean'
                }
            },
            authorisationSignForBankAccountWithoutOtherSignaturesInd: {
                serializedName: 'authorisationSignForBankAccountWithoutOtherSignaturesInd',
                type: {
                    name: 'Boolean'
                }
            },
            authorisationPermissionToDeductMoneyFromTheBankAccountNumberInd: {
                serializedName: 'authorisationPermissionToDeductMoneyFromTheBankAccountNumberInd',
                type: {
                    name: 'Boolean'
                }
            }
        }
    }
};
export const CreateCreditCardChangeRequest = {
    serializedName: 'CreateCreditCardChangeRequest',
    type: {
        name: 'Composite',
        className: 'CreateCreditCardChangeRequest',
        modelProperties: {
            policyNumbers: {
                required: true,
                serializedName: 'policyNumbers',
                type: {
                    name: 'Sequence',
                    element: {
                        serializedName: 'stringElementType',
                        type: {
                            name: 'String'
                        }
                    }
                }
            },
            finalSuccessUrl: {
                required: true,
                serializedName: 'finalSuccessUrl',
                type: {
                    name: 'String'
                }
            },
            failUrl: {
                required: true,
                serializedName: 'failUrl',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
export const CreditCardPaymentPageResponse = {
    serializedName: 'CreditCardPaymentPageResponse',
    type: {
        name: 'Composite',
        className: 'CreditCardPaymentPageResponse',
        modelProperties: {
            url: {
                serializedName: 'url',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
export const PayNowPolicyRequest = {
    serializedName: 'PayNowPolicyRequest',
    type: {
        name: 'Composite',
        className: 'PayNowPolicyRequest',
        modelProperties: {
            finalSuccessUrl: {
                serializedName: 'finalSuccessUrl',
                type: {
                    name: 'String'
                }
            },
            failUrl: {
                serializedName: 'failUrl',
                type: {
                    name: 'String'
                }
            },
            amounts: {
                required: true,
                serializedName: 'amounts',
                type: {
                    name: 'Sequence',
                    element: {
                        serializedName: 'numberElementType',
                        type: {
                            name: 'Number'
                        }
                    }
                }
            },
            policyEffectiveDates: {
                required: true,
                serializedName: 'policyEffectiveDates',
                type: {
                    name: 'Sequence',
                    element: {
                        serializedName: 'stringElementType',
                        type: {
                            name: 'String'
                        }
                    }
                }
            },
            policies: {
                serializedName: 'policies',
                type: {
                    name: 'Sequence',
                    element: {
                        serializedName: 'stringElementType',
                        type: {
                            name: 'String'
                        }
                    }
                }
            },
            billingAccountNumbers: {
                serializedName: 'billingAccountNumbers',
                type: {
                    name: 'Sequence',
                    element: {
                        serializedName: 'stringElementType',
                        type: {
                            name: 'String'
                        }
                    }
                }
            }
        }
    }
};
export const BankAccountPolicyPurchaseRequest = {
    serializedName: 'BankAccountPolicyPurchaseRequest',
    type: {
        name: 'Composite',
        className: 'BankAccountPolicyPurchaseRequest',
        modelProperties: {
            bankName: {
                required: true,
                serializedName: 'bankName',
                type: {
                    name: 'String'
                }
            },
            bankAccountName: {
                required: true,
                serializedName: 'bankAccountName',
                constraints: {
                    Pattern: /[a-zA-Z ]+/
                },
                type: {
                    name: 'String'
                }
            },
            bankAccountNumber: {
                required: true,
                serializedName: 'bankAccountNumber',
                constraints: {
                    Pattern: /[0-9]{16}/
                },
                type: {
                    name: 'String'
                }
            },
            authorisationSetupDirectDebitAuthorityWithoutSigningFormInd: {
                required: true,
                serializedName: 'authorisationSetupDirectDebitAuthorityWithoutSigningFormInd',
                type: {
                    name: 'Boolean'
                }
            },
            authorisationCancelledDirectDebitAuthorityWithTowerInsuranceInd: {
                required: true,
                serializedName: 'authorisationCancelledDirectDebitAuthorityWithTowerInsuranceInd',
                type: {
                    name: 'Boolean'
                }
            },
            authorisationSignForBankAccountWithoutOtherSignaturesInd: {
                serializedName: 'authorisationSignForBankAccountWithoutOtherSignaturesInd',
                type: {
                    name: 'Boolean'
                }
            },
            authorisationPermissionToDeductMoneyFromTheBankAccountNumberInd: {
                serializedName: 'authorisationPermissionToDeductMoneyFromTheBankAccountNumberInd',
                type: {
                    name: 'Boolean'
                }
            },
            bundleId: {
                required: true,
                serializedName: 'bundleId',
                constraints: {
                    Pattern: /^[a-zA-Z0-9._-]*$/
                },
                type: {
                    name: 'String'
                }
            },
            billingAccountNumber: {
                serializedName: 'billingAccountNumber',
                type: {
                    name: 'String'
                }
            },
            plan: {
                required: true,
                serializedName: 'plan',
                type: {
                    name: 'String'
                }
            },
            recurring: {
                required: true,
                serializedName: 'recurring',
                type: {
                    name: 'Boolean'
                }
            },
            firstPaymentDate: {
                serializedName: 'firstPaymentDate',
                constraints: {
                    Pattern: /[\d]{4}-[\d]{2}-[\d]{2}/
                },
                type: {
                    name: 'String'
                }
            },
            paymentDayOfMonth: {
                serializedName: 'paymentDayOfMonth',
                constraints: {
                    InclusiveMaximum: 31,
                    InclusiveMinimum: 1
                },
                type: {
                    name: 'Number'
                }
            }
        }
    }
};
export const CreateCreditCardPolicyPurchaseRequest = {
    serializedName: 'CreateCreditCardPolicyPurchaseRequest',
    type: {
        name: 'Composite',
        className: 'CreateCreditCardPolicyPurchaseRequest',
        modelProperties: {
            transactionType: {
                required: true,
                serializedName: 'transactionType',
                type: {
                    name: 'String'
                }
            },
            amount: {
                required: true,
                serializedName: 'amount',
                type: {
                    name: 'Number'
                }
            },
            finalSuccessUrl: {
                required: true,
                serializedName: 'finalSuccessUrl',
                type: {
                    name: 'String'
                }
            },
            failUrl: {
                required: true,
                serializedName: 'failUrl',
                type: {
                    name: 'String'
                }
            },
            bundleId: {
                required: true,
                serializedName: 'bundleId',
                constraints: {
                    Pattern: /^[a-zA-Z0-9._-]*$/
                },
                type: {
                    name: 'String'
                }
            },
            billingAccountNumber: {
                serializedName: 'billingAccountNumber',
                type: {
                    name: 'String'
                }
            },
            plan: {
                required: true,
                serializedName: 'plan',
                type: {
                    name: 'String'
                }
            },
            recurring: {
                required: true,
                serializedName: 'recurring',
                type: {
                    name: 'Boolean'
                }
            },
            firstPaymentDate: {
                serializedName: 'firstPaymentDate',
                constraints: {
                    Pattern: /[\d]{4}-[\d]{2}-[\d]{2}/
                },
                type: {
                    name: 'String'
                }
            },
            paymentDayOfMonth: {
                serializedName: 'paymentDayOfMonth',
                constraints: {
                    InclusiveMaximum: 31,
                    InclusiveMinimum: 1
                },
                type: {
                    name: 'Number'
                }
            }
        }
    }
};
export const PaymentApiConfirmPayNowV2OptionalParams = {
    serializedName: 'ConfirmPayNowV2Options',
    type: {
        name: 'Composite',
        className: 'PaymentApiConfirmPayNowV2OptionalParams',
        modelProperties: {
            finalSuccessUrl: {
                serializedName: 'FinalSuccessUrl',
                type: {
                    name: 'String'
                }
            },
            failUrl: {
                serializedName: 'FailUrl',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
export const PaymentApiConfirmCreditCardPurchaseV2OptionalParams = {
    serializedName: 'ConfirmCreditCardPurchaseV2Options',
    type: {
        name: 'Composite',
        className: 'PaymentApiConfirmCreditCardPurchaseV2OptionalParams',
        modelProperties: {
            firstPaymentDate: {
                serializedName: 'FirstPaymentDate',
                constraints: {
                    Pattern: /[\d]{4}-[\d]{2}-[\d]{2}/
                },
                type: {
                    name: 'String'
                }
            },
            paymentDayOfMonth: {
                serializedName: 'PaymentDayOfMonth',
                constraints: {
                    InclusiveMaximum: 31,
                    InclusiveMinimum: 1
                },
                type: {
                    name: 'Number'
                }
            }
        }
    }
};
