/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
/**
 * Defines values for Plan.
 * Possible values include: 'annual', 'fortnightly', 'monthly'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: Plan = <Plan>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var Plan;
(function (Plan) {
    Plan["Annual"] = "annual";
    Plan["Fortnightly"] = "fortnightly";
    Plan["Monthly"] = "monthly";
})(Plan || (Plan = {}));
/**
 * Defines values for TransactionType.
 * Possible values include: 'purchase', 'authorisation'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: TransactionType =
 * <TransactionType>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var TransactionType;
(function (TransactionType) {
    TransactionType["Purchase"] = "purchase";
    TransactionType["Authorisation"] = "authorisation";
})(TransactionType || (TransactionType = {}));
/**
 * Defines values for XBrandId.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId = <XBrandId>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId;
(function (XBrandId) {
    XBrandId["TWR"] = "TWR";
    XBrandId["TMI"] = "TMI";
    XBrandId["TSB"] = "TSB";
    XBrandId["AIR"] = "AIR";
})(XBrandId || (XBrandId = {}));
/**
 * Defines values for XBrandId1.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId1 = <XBrandId1>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId1;
(function (XBrandId1) {
    XBrandId1["TWR"] = "TWR";
    XBrandId1["TMI"] = "TMI";
    XBrandId1["TSB"] = "TSB";
    XBrandId1["AIR"] = "AIR";
})(XBrandId1 || (XBrandId1 = {}));
/**
 * Defines values for XBrandId2.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId2 = <XBrandId2>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId2;
(function (XBrandId2) {
    XBrandId2["TWR"] = "TWR";
    XBrandId2["TMI"] = "TMI";
    XBrandId2["TSB"] = "TSB";
    XBrandId2["AIR"] = "AIR";
})(XBrandId2 || (XBrandId2 = {}));
/**
 * Defines values for XBrandId3.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId3 = <XBrandId3>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId3;
(function (XBrandId3) {
    XBrandId3["TWR"] = "TWR";
    XBrandId3["TMI"] = "TMI";
    XBrandId3["TSB"] = "TSB";
    XBrandId3["AIR"] = "AIR";
})(XBrandId3 || (XBrandId3 = {}));
/**
 * Defines values for XBrandId4.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId4 = <XBrandId4>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId4;
(function (XBrandId4) {
    XBrandId4["TWR"] = "TWR";
    XBrandId4["TMI"] = "TMI";
    XBrandId4["TSB"] = "TSB";
    XBrandId4["AIR"] = "AIR";
})(XBrandId4 || (XBrandId4 = {}));
/**
 * Defines values for TransactionType1.
 * Possible values include: 'purchase', 'authorisation'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: TransactionType1 =
 * <TransactionType1>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var TransactionType1;
(function (TransactionType1) {
    TransactionType1["Purchase"] = "purchase";
    TransactionType1["Authorisation"] = "authorisation";
})(TransactionType1 || (TransactionType1 = {}));
/**
 * Defines values for XBrandId5.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId5 = <XBrandId5>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId5;
(function (XBrandId5) {
    XBrandId5["TWR"] = "TWR";
    XBrandId5["TMI"] = "TMI";
    XBrandId5["TSB"] = "TSB";
    XBrandId5["AIR"] = "AIR";
})(XBrandId5 || (XBrandId5 = {}));
/**
 * Defines values for XBrandId6.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId6 = <XBrandId6>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId6;
(function (XBrandId6) {
    XBrandId6["TWR"] = "TWR";
    XBrandId6["TMI"] = "TMI";
    XBrandId6["TSB"] = "TSB";
    XBrandId6["AIR"] = "AIR";
})(XBrandId6 || (XBrandId6 = {}));
/**
 * Defines values for XBrandId7.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId7 = <XBrandId7>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId7;
(function (XBrandId7) {
    XBrandId7["TWR"] = "TWR";
    XBrandId7["TMI"] = "TMI";
    XBrandId7["TSB"] = "TSB";
    XBrandId7["AIR"] = "AIR";
})(XBrandId7 || (XBrandId7 = {}));
/**
 * Defines values for TransactionType2.
 * Possible values include: 'purchase', 'authorisation'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: TransactionType2 =
 * <TransactionType2>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var TransactionType2;
(function (TransactionType2) {
    TransactionType2["Purchase"] = "purchase";
    TransactionType2["Authorisation"] = "authorisation";
})(TransactionType2 || (TransactionType2 = {}));
/**
 * Defines values for Plan1.
 * Possible values include: 'annual', 'fortnightly', 'monthly'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: Plan1 = <Plan1>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var Plan1;
(function (Plan1) {
    Plan1["Annual"] = "annual";
    Plan1["Fortnightly"] = "fortnightly";
    Plan1["Monthly"] = "monthly";
})(Plan1 || (Plan1 = {}));
/**
 * Defines values for XBrandId8.
 * Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
 * There could be more values for this enum apart from the ones defined here.If
 * you want to set a value that is not from the known values then you can do
 * the following:
 * let param: XBrandId8 = <XBrandId8>"someUnknownValueThatWillStillBeValid";
 * @readonly
 * @enum {string}
 */
export var XBrandId8;
(function (XBrandId8) {
    XBrandId8["TWR"] = "TWR";
    XBrandId8["TMI"] = "TMI";
    XBrandId8["TSB"] = "TSB";
    XBrandId8["AIR"] = "AIR";
})(XBrandId8 || (XBrandId8 = {}));
