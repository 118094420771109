/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
import { __awaiter } from "tslib";
import * as msRest from 'ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import { PaymentApiContext } from './paymentApiContext';
const WebResource = msRest.WebResource;
class PaymentApi extends PaymentApiContext {
    /**
     * @class
     * Initializes a new instance of the PaymentApi class.
     * @constructor
     *
     * @param {string} [baseUri] - The base URI of the service.
     *
     * @param {object} [options] - The parameter options
     *
     * @param {Array} [options.filters] - Filters to be added to the request pipeline
     *
     * @param {object} [options.requestOptions] - The request options. Detailed info can be found at
     * {@link https://github.github.io/fetch/#Request Options doc}
     *
     * @param {boolean} [options.noRetryPolicy] - If set to true, turn off default retry policy
     *
     */
    constructor(baseUri, options) {
        super(baseUri, options);
        this.serializer = new msRest.Serializer(Mappers);
    }
    // methods on the client.
    /**
     * @summary Validates a bank account number.
     *
     * @param {string} bankAccountNumber Bank account number in the format 1122223333333444.
     *
     * @param {XBrandId} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    validateBankAccountWithHttpOperationResponse(bankAccountNumber, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    bankAccountNumber,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'GET',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/bank-account/validate/{BankAccountNumber}',
                    urlParameters: [
                        {
                            parameterPath: 'bankAccountNumber',
                            mapper: {
                                required: true,
                                serializedName: 'BankAccountNumber',
                                constraints: {
                                    Pattern: /[0-9]{16}/
                                },
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    responses: {
                        200: {
                            bodyMapper: Mappers.ValidateBankAccountResponse
                        },
                        400: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
                // Deserialize Response
                let statusCode = operationRes.status;
                if (statusCode === 200) {
                    let parsedResponse = operationRes.parsedBody;
                    try {
                        if (parsedResponse != undefined) {
                            const resultMapper = Mappers.ValidateBankAccountResponse;
                            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
                        }
                    }
                    catch (error) {
                        let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
                        deserializationError.request = msRest.stripRequest(httpRequest);
                        deserializationError.response = msRest.stripResponse(operationRes);
                        return Promise.reject(deserializationError);
                    }
                }
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Change direct debit payment method.
     *
     * @param {BankAccountChangeRequest} request
     *
     * @param {XBrandId1} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    bankAccountChangeWithHttpOperationResponse(request, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    request,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'POST',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/change/method/bank-account',
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    requestBody: {
                        parameterPath: 'request',
                        mapper: Object.assign(Object.assign({}, Mappers.BankAccountChangeRequest), { required: true })
                    },
                    contentType: 'application/json-patch+json; charset=utf-8',
                    responses: {
                        202: {},
                        400: {},
                        422: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Create credit card authorisation for payment method change.
     *
     * @param {CreateCreditCardChangeRequest} request Details of the change.
     *
     * @param {XBrandId2} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    createCreditCardChangeWithHttpOperationResponse(request, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    request,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'POST',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/change/method/credit-card',
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    requestBody: {
                        parameterPath: 'request',
                        mapper: Object.assign(Object.assign({}, Mappers.CreateCreditCardChangeRequest), { required: true })
                    },
                    contentType: 'application/json-patch+json; charset=utf-8',
                    responses: {
                        200: {
                            bodyMapper: Mappers.CreditCardPaymentPageResponse
                        },
                        400: {},
                        422: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
                // Deserialize Response
                let statusCode = operationRes.status;
                if (statusCode === 200) {
                    let parsedResponse = operationRes.parsedBody;
                    try {
                        if (parsedResponse != undefined) {
                            const resultMapper = Mappers.CreditCardPaymentPageResponse;
                            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
                        }
                    }
                    catch (error) {
                        let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
                        deserializationError.request = msRest.stripRequest(httpRequest);
                        deserializationError.response = msRest.stripResponse(operationRes);
                        return Promise.reject(deserializationError);
                    }
                }
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Confirm a credit card authorisation for payment details change.
     *
     * @param {string} result The result code returned by a CreateCreditCardPayment request.
     *
     * @param {string} finalSuccessUrl The URL in the UI that the client will ultimately be redirected
     * to on success.
     *
     * @param {string} failUrl The URL in the UI that the client will ultimately be redirected to on
     * failure.
     *
     * @param {string} brandId Identifies the brand
     *
     * @param {XBrandId3} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    confirmCreditCardChangeWithHttpOperationResponse(result, finalSuccessUrl, failUrl, brandId, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    result,
                    finalSuccessUrl,
                    failUrl,
                    brandId,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'GET',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/change/method/credit-card/confirm',
                    queryParameters: [
                        {
                            parameterPath: 'result',
                            mapper: {
                                required: true,
                                serializedName: 'Result',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'finalSuccessUrl',
                            mapper: {
                                required: true,
                                serializedName: 'FinalSuccessUrl',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'failUrl',
                            mapper: {
                                required: true,
                                serializedName: 'FailUrl',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'brandId',
                            mapper: {
                                required: true,
                                serializedName: 'BrandId',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    responses: {
                        302: {},
                        400: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Create credit card payment for 'pay now' requests from the self service portal.
     *
     * @param {PayNowPolicyRequest} request Details of the payment to be created.
     *
     * @param {XBrandId4} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    createPayNowWithHttpOperationResponse(request, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    request,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'POST',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/pay-now/policy',
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    requestBody: {
                        parameterPath: 'request',
                        mapper: Object.assign(Object.assign({}, Mappers.PayNowPolicyRequest), { required: true })
                    },
                    contentType: 'application/json-patch+json; charset=utf-8',
                    responses: {
                        200: {
                            bodyMapper: Mappers.CreditCardPaymentPageResponse
                        },
                        400: {},
                        422: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
                // Deserialize Response
                let statusCode = operationRes.status;
                if (statusCode === 200) {
                    let parsedResponse = operationRes.parsedBody;
                    try {
                        if (parsedResponse != undefined) {
                            const resultMapper = Mappers.CreditCardPaymentPageResponse;
                            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
                        }
                    }
                    catch (error) {
                        let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
                        deserializationError.request = msRest.stripRequest(httpRequest);
                        deserializationError.response = msRest.stripResponse(operationRes);
                        return Promise.reject(deserializationError);
                    }
                }
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Confirm a 'pay now' credit card payment that was made from the self service portal.
     *
     * @param {string} result The result code returned by a ConfirmPayNowRequest request.
     *
     * @param {string} brandId Identifies the brand
     *
     * @param {TransactionType1} transactionType Gets or Sets TransactionType. Possible values include:
     * 'purchase', 'authorisation'
     *
     * @param {string} transactionDetails Transaction details for the pay now transaction
     *
     * @param {XBrandId5} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {PaymentApiConfirmPayNowV2OptionalParams} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    confirmPayNowV2WithHttpOperationResponse(result, brandId, transactionType, transactionDetails, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let finalSuccessUrl = options && options.finalSuccessUrl !== undefined ? options.finalSuccessUrl : undefined;
            let failUrl = options && options.failUrl !== undefined ? options.failUrl : undefined;
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    result,
                    finalSuccessUrl,
                    failUrl,
                    brandId,
                    transactionType,
                    transactionDetails,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'GET',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/pay-now/policy/confirm',
                    queryParameters: [
                        {
                            parameterPath: 'result',
                            mapper: {
                                required: true,
                                serializedName: 'Result',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'finalSuccessUrl',
                            mapper: {
                                serializedName: 'FinalSuccessUrl',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'failUrl',
                            mapper: {
                                serializedName: 'FailUrl',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'brandId',
                            mapper: {
                                required: true,
                                serializedName: 'BrandId',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'transactionType',
                            mapper: {
                                required: true,
                                serializedName: 'TransactionType',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'transactionDetails',
                            mapper: {
                                required: true,
                                serializedName: 'TransactionDetails',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    responses: {
                        302: {},
                        400: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Purchase a policy bundle via bank account direct debit.
     *
     * @param {BankAccountPolicyPurchaseRequest} request
     *
     * @param {XBrandId6} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    bankAccountPurchaseWithHttpOperationResponse(request, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    request,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'POST',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/purchase-bundle/bank-account',
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    requestBody: {
                        parameterPath: 'request',
                        mapper: Object.assign(Object.assign({}, Mappers.BankAccountPolicyPurchaseRequest), { required: true })
                    },
                    contentType: 'application/json-patch+json; charset=utf-8',
                    responses: {
                        202: {},
                        400: {},
                        404: {},
                        422: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Create credit card payment and/or authorisation to purchase a policy bundle.
     *
     * @param {CreateCreditCardPolicyPurchaseRequest} request Details of the payment to be created.
     *
     * @param {XBrandId7} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    createCreditCardPurchaseWithHttpOperationResponse(request, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    request,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'POST',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/purchase-bundle/credit-card',
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    requestBody: {
                        parameterPath: 'request',
                        mapper: Object.assign(Object.assign({}, Mappers.CreateCreditCardPolicyPurchaseRequest), { required: true })
                    },
                    contentType: 'application/json-patch+json; charset=utf-8',
                    responses: {
                        200: {
                            bodyMapper: Mappers.CreditCardPaymentPageResponse
                        },
                        400: {},
                        404: {},
                        422: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
                // Deserialize Response
                let statusCode = operationRes.status;
                if (statusCode === 200) {
                    let parsedResponse = operationRes.parsedBody;
                    try {
                        if (parsedResponse != undefined) {
                            const resultMapper = Mappers.CreditCardPaymentPageResponse;
                            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
                        }
                    }
                    catch (error) {
                        let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
                        deserializationError.request = msRest.stripRequest(httpRequest);
                        deserializationError.response = msRest.stripResponse(operationRes);
                        return Promise.reject(deserializationError);
                    }
                }
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    // methods on the client.
    /**
     * @summary Confirm a credit card payment and/or authorisation and purchase the polices.
     *
     * @param {string} result The result code returned by a CreateCreditCardPayment request.
     *
     * @param {string} finalSuccessUrl The URL in the UI that the client will ultimately be redirected
     * to on success.
     *
     * @param {string} failUrl The URL in the UI that the client will ultimately be redirected to on
     * failure.
     *
     * @param {string} brandId Identifies the brand
     *
     * @param {TransactionType2} transactionType Gets or Sets TransactionType. Possible values include:
     * 'purchase', 'authorisation'
     *
     * @param {Plan1} plan The payment frequency. Possible values include: 'annual', 'fortnightly',
     * 'monthly'
     *
     * @param {boolean} recurring Whether the payment is a recurring payment or not.
     *
     * @param {XBrandId8} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {PaymentApiConfirmCreditCardPurchaseV2OptionalParams} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    confirmCreditCardPurchaseV2WithHttpOperationResponse(result, finalSuccessUrl, failUrl, brandId, transactionType, plan, recurring, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let firstPaymentDate = options && options.firstPaymentDate !== undefined ? options.firstPaymentDate : undefined;
            let paymentDayOfMonth = options && options.paymentDayOfMonth !== undefined ? options.paymentDayOfMonth : undefined;
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    result,
                    finalSuccessUrl,
                    failUrl,
                    brandId,
                    transactionType,
                    plan,
                    recurring,
                    firstPaymentDate,
                    paymentDayOfMonth,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'GET',
                    baseUrl: this.baseUri,
                    path: 'payment/v2/purchase-bundle/credit-card/confirm',
                    queryParameters: [
                        {
                            parameterPath: 'result',
                            mapper: {
                                required: true,
                                serializedName: 'Result',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'finalSuccessUrl',
                            mapper: {
                                required: true,
                                serializedName: 'FinalSuccessUrl',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'failUrl',
                            mapper: {
                                required: true,
                                serializedName: 'FailUrl',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'brandId',
                            mapper: {
                                required: true,
                                serializedName: 'BrandId',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'transactionType',
                            mapper: {
                                required: true,
                                serializedName: 'TransactionType',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'plan',
                            mapper: {
                                required: true,
                                serializedName: 'Plan',
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'recurring',
                            mapper: {
                                required: true,
                                serializedName: 'Recurring',
                                type: {
                                    name: 'Boolean'
                                }
                            }
                        },
                        {
                            parameterPath: 'firstPaymentDate',
                            mapper: {
                                serializedName: 'FirstPaymentDate',
                                constraints: {
                                    Pattern: /[\d]{4}-[\d]{2}-[\d]{2}/
                                },
                                type: {
                                    name: 'String'
                                }
                            }
                        },
                        {
                            parameterPath: 'paymentDayOfMonth',
                            mapper: {
                                serializedName: 'PaymentDayOfMonth',
                                constraints: {
                                    InclusiveMaximum: 31,
                                    InclusiveMinimum: 1
                                },
                                type: {
                                    name: 'Number'
                                }
                            }
                        }
                    ],
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    responses: {
                        302: {},
                        400: {},
                        500: {},
                        default: {}
                    },
                    serializer: this.serializer
                });
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    validateBankAccount(bankAccountNumber, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.validateBankAccountWithHttpOperationResponse(bankAccountNumber, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.validateBankAccountWithHttpOperationResponse(bankAccountNumber, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    bankAccountChange(request, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.bankAccountChangeWithHttpOperationResponse(request, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.bankAccountChangeWithHttpOperationResponse(request, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    createCreditCardChange(request, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.createCreditCardChangeWithHttpOperationResponse(request, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.createCreditCardChangeWithHttpOperationResponse(request, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    confirmCreditCardChange(result, finalSuccessUrl, failUrl, brandId, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.confirmCreditCardChangeWithHttpOperationResponse(result, finalSuccessUrl, failUrl, brandId, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.confirmCreditCardChangeWithHttpOperationResponse(result, finalSuccessUrl, failUrl, brandId, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    createPayNow(request, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.createPayNowWithHttpOperationResponse(request, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.createPayNowWithHttpOperationResponse(request, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    confirmPayNowV2(result, brandId, transactionType, transactionDetails, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.confirmPayNowV2WithHttpOperationResponse(result, brandId, transactionType, transactionDetails, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.confirmPayNowV2WithHttpOperationResponse(result, brandId, transactionType, transactionDetails, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    bankAccountPurchase(request, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.bankAccountPurchaseWithHttpOperationResponse(request, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.bankAccountPurchaseWithHttpOperationResponse(request, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    createCreditCardPurchase(request, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.createCreditCardPurchaseWithHttpOperationResponse(request, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.createCreditCardPurchaseWithHttpOperationResponse(request, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
    confirmCreditCardPurchaseV2(result, finalSuccessUrl, failUrl, brandId, transactionType, plan, recurring, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.confirmCreditCardPurchaseV2WithHttpOperationResponse(result, finalSuccessUrl, failUrl, brandId, transactionType, plan, recurring, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.confirmCreditCardPurchaseV2WithHttpOperationResponse(result, finalSuccessUrl, failUrl, brandId, transactionType, plan, recurring, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
}
export { PaymentApi, Models as PaymentApiModels, Mappers as PaymentApiMappers };
