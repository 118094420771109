import * as React from 'react';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import './QuestionTick.scss';
export const QuestionTick = ({ show, error, overrideQuestionNumber }) => {
    let innerCls = '';
    if (show) {
        innerCls = 'question-tick-number--success';
    }
    else if (error) {
        innerCls = 'question-tick-number--error';
    }
    return (React.createElement("div", { className: "question-tick", "aria-hidden": "true" },
        !show && !error && (
        // @ts-ignore
        React.createElement(FontIcon, { className: "question-tick-icon question-tick-icon--default", role: "presentation" }, "done")),
        show && (
        // @ts-ignore
        React.createElement(FontIcon, { className: "question-tick-icon question-tick-icon--success", role: "presentation" }, "done")),
        error && (
        // @ts-ignore
        React.createElement(FontIcon, { className: "question-tick-icon question-tick-icon--error", role: "presentation" }, "clear")),
        React.createElement("span", { className: 'question-tick-number ' + innerCls, role: "presentation" }, overrideQuestionNumber)));
};
