import * as React from 'react';
import { translate } from 'react-i18next';
import './Footer.scss';
export const FooterLink = ({ linkKey, labelKey, t }) => {
    const linkValue = t(linkKey);
    return linkValue && linkValue !== linkKey ? (React.createElement("div", { className: "footer-link-container" },
        React.createElement("a", { href: linkValue, target: "_blank" }, t(labelKey)))) : null;
};
export const FooterComponent = ({ t }) => (React.createElement("footer", { id: "footer" },
    React.createElement("div", { className: "footer-content" },
        React.createElement("span", null, t('footer.copyright')),
        React.createElement("div", { className: "footer-links" },
            React.createElement(FooterLink, { linkKey: "links.termsAndConditions", labelKey: "footer.termsAndConditions", t: t }),
            React.createElement(FooterLink, { linkKey: "links.privacyPolicy", labelKey: "footer.privacyAndSecurity", t: t }),
            React.createElement(FooterLink, { linkKey: "links.fairInsuranceCode", labelKey: "footer.fairInsuranceCode", t: t }),
            React.createElement(FooterLink, { linkKey: "links.qfeDisclosure", labelKey: "footer.qfeDisclosure", t: t })))));
export const Footer = translate()(FooterComponent);
export default Footer;
