import * as React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
// Workaround for a bug where the IntlTelInput default value is not applied when the MDTextField is unmounted and remounted.
// Here we use the 'key' prop to force a brand new IntlTelInput node to be created whenever the wrapper is mounted.
export class IntlTelInputWrapper extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { key: '' };
    }
    componentDidMount() {
        this.createKey();
    }
    render() {
        const { key } = this.state;
        return key && React.createElement(IntlTelInput, Object.assign({ key: key }, this.props));
    }
    createKey() {
        this.setState({ key: 'key' + Math.random() });
    }
}
