import { __awaiter } from "tslib";
import * as React from 'react';
import { translate } from 'react-i18next';
import { Errors, actions as formActions } from 'react-redux-form';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import { MDTextField, TooltipIcon, Question } from '~/common/components';
import { handlePaste } from '~/common/utilities';
import { actions } from '~/common/state';
import store from '~/root/store';
import './PaymentBankAccountNumber.scss';
export class PaymentBankAccountNumberComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pending: false,
        };
        this.handelePasteBankAccount = (e) => {
            const { modelPath } = this.props;
            const model = `${modelPath}.paymentBankAccount.bankAccountNumber`;
            const pasteData = handlePaste(e);
            if (pasteData && pasteData.length > 0) {
                const accountNumber = pasteData.replace(/-/g, '').replace(/ /g, '');
                if (accountNumber && accountNumber.length >= 15 && accountNumber.length <= 17) {
                    // Stop data actually being pasted into control
                    e.stopPropagation();
                    e.preventDefault();
                    const bank = accountNumber.slice(0, 2);
                    const branch = accountNumber.slice(2, 6);
                    const account = accountNumber.slice(6, 13);
                    const suffix = accountNumber.slice(13);
                    store.dispatch(formActions.change(model, {
                        bank: bank,
                        branch: branch,
                        account: account,
                        suffix: suffix,
                    }));
                    this.validateAccount();
                }
            }
        };
        this.validateAccount = () => __awaiter(this, void 0, void 0, function* () {
            this.forceUpdate();
            setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                const { modelPath, paymentState } = this.props;
                this.setState({ pending: true });
                yield actions.handleValidateBankAccount(modelPath, paymentState.paymentBankAccount.bankAccountNumber)(store.dispatch);
                this.setState({ pending: false });
            }), 0);
        });
    }
    render() {
        const { modelPath, paymentState, handleOnChange } = this.props;
        const { pending } = this.state;
        const { bankName, bankAccountNumber } = paymentState.paymentBankAccount;
        const model = `${modelPath}.paymentBankAccount.bankAccountNumber`;
        return (React.createElement(Question, { id: "questionPaymentBankAccountNumber", model: model, noTick: true, translation: "base:paymentBankAccountNumber", subQuestion: true },
            React.createElement("div", { className: "bank-account-field-wrapper" },
                React.createElement(MDTextField, { id: `txtBankAccountNumberBank`, model: `${model}.bank`, label: "Bank", placeholder: "00", validateOn: ['blur', 'change'], maxLength: 2, onChange: (val) => {
                        if (val.length === 2) {
                            const ele = document.querySelector('#txtBankAccountNumberBranch');
                            if (ele.value.length !== 4) {
                                ele.focus();
                            }
                        }
                        const existingBank = bankAccountNumber.bank;
                        if (existingBank !== val) {
                            this.validateAccount();
                        }
                        handleOnChange();
                    }, onPaste: this.handelePasteBankAccount }),
                React.createElement(MDTextField, { id: `txtBankAccountNumberBranch`, model: `${model}.branch`, label: "Branch", placeholder: "0000", validateOn: ['blur', 'change'], maxLength: 4, onChange: (val) => {
                        if (val.length === 4) {
                            const ele = document.querySelector('#txtBankAccountNumberAccount');
                            if (ele.value.length !== 7) {
                                ele.focus();
                            }
                        }
                        const existingBranch = bankAccountNumber.branch;
                        if (existingBranch !== val) {
                            this.validateAccount();
                        }
                        handleOnChange();
                    }, onPaste: this.handelePasteBankAccount }),
                React.createElement(MDTextField, { id: `txtBankAccountNumberAccount`, model: `${model}.account`, label: "Account", placeholder: "000000", validateOn: ['blur', 'change'], maxLength: 7, onChange: (val) => {
                        if (val.length === 7) {
                            const ele = document.querySelector('#txtBankAccountNumberSuffix');
                            if (ele.value.length !== 3) {
                                ele.focus();
                            }
                        }
                        const existingAccount = bankAccountNumber.account;
                        if (existingAccount !== val) {
                            this.validateAccount();
                        }
                        handleOnChange();
                    }, onPaste: this.handelePasteBankAccount }),
                React.createElement(MDTextField, { id: `txtBankAccountNumberSuffix`, model: `${model}.suffix`, label: "Suffix", placeholder: "000", validateOn: ['blur', 'change'], maxLength: 4, onChange: (val) => {
                        const existingSuffix = bankAccountNumber.suffix;
                        if (existingSuffix !== val) {
                            this.validateAccount();
                        }
                        handleOnChange();
                    }, onPaste: this.handelePasteBankAccount }),
                React.createElement("span", { className: `spinner ${pending ? 'visible' : ''}` }),
                bankName && React.createElement(FontIcon, { className: "tick" }, "done")),
            React.createElement("p", null,
                React.createElement("small", null, "If your account suffix is three digits, leave out the first zero e.g. 012 will be 12")),
            React.createElement("br", null),
            React.createElement("div", { className: "errors-bankaccount" },
                React.createElement(Errors, { className: "form-error-container", model: `${model}`, show: true })),
            bankName && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "bankname-container" },
                    React.createElement("p", null,
                        React.createElement("span", { className: "bold" },
                            this.props.t('base:paymentBankName.title'),
                            ":"),
                        React.createElement(TooltipIcon, { label: this.props.t('base:paymentBankName.tooltip'), parentClass: ".bankname-tooltip-container" }),
                        ' ',
                        React.createElement("span", { id: "bankName" }, bankName))),
                React.createElement("div", { className: "tooltip-container bankname-tooltip-container" })))));
    }
}
export const PaymentBankAccountNumber = translate(['base'])(PaymentBankAccountNumberComponent);
export default PaymentBankAccountNumber;
