import { actionTypes, getDefaultCommonState } from './constants';
// @ts-ignore
const optimizeTimeout = null;
export const commonReducer = (state = getDefaultCommonState(), action) => {
    switch (action.type) {
        case actionTypes.LOADING: {
            return Object.assign(Object.assign({}, state), { loading: action.loading });
        }
        default: {
        }
    }
    return state;
};
export default commonReducer;
