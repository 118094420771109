/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
import * as msRest from '@azure/ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import { RefundApiContext } from './refundApiContext';
class RefundApi extends RefundApiContext {
    /**
     * Initializes a new instance of the RefundApi class.
     * @param [options] The parameter options
     */
    constructor(options) {
        super(options);
    }
    submitBankAccountDetails(options, callback) {
        return this.sendOperationRequest({
            options
        }, submitBankAccountDetailsOperationSpec, callback);
    }
}
// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const submitBankAccountDetailsOperationSpec = {
    httpMethod: 'POST',
    path: 'refund/v1/submitBankAccountDetails',
    requestBody: {
        parameterPath: ['options', 'body'],
        mapper: Mappers.SubmitBankAccountDetailsRequest
    },
    responses: {
        200: {
            bodyMapper: Mappers.SubmitBankAccountDetailsResponse
        },
        400: {
            bodyMapper: Mappers.ProblemDetails
        },
        500: {},
        default: {}
    },
    serializer
};
export { RefundApi, RefundApiContext, Models as RefundApiModels, Mappers as RefundApiMappers };
