import * as React from 'react';
import { translate } from 'react-i18next';
import { actions as formActions } from 'react-redux-form';
import { MDTextField, Question } from '~/common/components';
import store from '~/root/store';
export const PaymentBankAccountNameComponent = (props) => {
    const { modelPath, handleOnChange } = props;
    const model = `${modelPath}.paymentBankAccount`;
    return (React.createElement(Question, { id: "questionPaymentBankAccountName", model: `${model}.bankAccountName`, noTick: true, translation: "base:paymentBankAccountName" },
        React.createElement(MDTextField, { id: `txtBankAccountName`, model: `${model}.bankAccountName`, label: "For example, Chloe Smith", placeholder: "Name", validateOn: ['blur', 'change'], maxLength: 80, showValidationTickIcon: true, onChange: (value) => {
                const newValue = (value || '').replace(/[^a-zA-Z ]/g, '');
                store.dispatch(formActions.change(`${model}.bankAccountName`, newValue));
                setTimeout(() => {
                    const element = document.querySelector('#questionPaymentBankAccountName input');
                    element.value = newValue;
                }, 0);
                handleOnChange();
            } })));
};
export const PaymentBankAccountName = translate(['base'])(PaymentBankAccountNameComponent);
export default PaymentBankAccountName;
