import { __rest } from "tslib";
import * as React from 'react';
import TextField from 'react-md/lib/TextFields/TextField';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import { Control, Errors, actions as formActions } from 'react-redux-form';
import { translate } from 'react-i18next';
import { isEmail, isPhoneNumber } from '~/common/utilities';
import { IntlTelInputWrapper } from './IntlTelInputWrapper';
import store from '~/root/store';
import './MDTextField.scss';
class ErrorBoundary extends React.Component {
    componentDidCatch(error, info) {
        console.log('componentDidCatch MDTextField', error, info);
    }
    render() {
        return this.props.children;
    }
}
// @ts-ignore
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
export const CustomTextField = (_a) => {
    var { isPhone, showValidationTickIcon, withFieldValue, fieldValue, required, // Remove this from 'rest' otherwise the TextField will put a '*' after the label.
    rightLabel } = _a, rest = __rest(_a, ["isPhone", "showValidationTickIcon", "withFieldValue", "fieldValue", "required", "rightLabel"]);
    const tickIconVisible = showValidationTickIcon &&
        fieldValue.valid &&
        fieldValue.value !== fieldValue.initialValue &&
        fieldValue.value !== fieldValue.loadedValue &&
        rest.value &&
        rest.value.length > 0;
    const inlineIndicator = showValidationTickIcon ? (React.createElement(FontIcon, { className: 'text-field-inline-tick' + (tickIconVisible ? ' showing' : ''), "aria-hidden": true }, "done")) : (undefined);
    const optionalRightLabelVisible = fieldValue && fieldValue.value !== '' && rest.value && rest.value.length > 0;
    const optionalRightLabel = rightLabel !== '' ? (React.createElement("span", { className: 'right-label' + (optionalRightLabelVisible ? ' hide' : '') }, rightLabel)) : (undefined);
    if (isPhone) {
        const phoneDefaultValue = rest.value || '';
        return (React.createElement(ErrorBoundary, null,
            rest.label ? (React.createElement("label", { className: "visually-hidden", htmlFor: rest.id }, rest.label)) : null,
            React.createElement(IntlTelInputWrapper, { fieldId: rest.id, fieldName: rest.name, css: [
                    'intl-tel-input md-text-field-container md-full-width md-text-field-container--input',
                    'md-text-field md-text-field--inline-indicator md-text-field--floating-margin md-full-width md-text'
                ], defaultValue: phoneDefaultValue.replace(/(?!^)\+/, '').replace(/[^0-9+]/g, ''), defaultCountry: "nz", format: false, formatOnInit: false, nationalMode: false, separateDialCode: true, autoHideDialCode: false, preferredCountries: ['nz', 'au', 'gb', 'us'], placeholder: rest.placeholder, excludeCountries: [
                    'ad',
                    'ax',
                    'bl',
                    'bq',
                    'cd',
                    'cw',
                    'gg',
                    'im',
                    'je',
                    'me',
                    'mf',
                    'ps',
                    'rs',
                    'ss',
                    'sx',
                    'tl',
                    'xk'
                ], telInputProps: {
                    maxLength: 20
                }, 
                // @ts-ignore
                onSelectFlag: (value, countryData, phoneNumber, status) => {
                    if (value === '') {
                        rest.onChange('');
                    }
                    else {
                        rest.onChange(phoneNumber.replace(/(?!^)\+/, '').replace(/[^0-9+]/g, ''));
                    }
                    store.dispatch(formActions.change(`${rest.name}Country`, countryData.iso2));
                }, 
                // @ts-ignore
                onPhoneNumberChange: (status, value, countryData, phoneNumber) => {
                    if (value === '') {
                        rest.onChange('');
                    }
                    else {
                        rest.onChange(phoneNumber.replace(/(?!^)\+/, '').replace(/[^0-9+]/g, ''));
                    }
                    // // hack to enforce no dodgy characters
                    const ele = document.querySelector(`#${rest.id}`);
                    try {
                        nativeInputValueSetter.call(ele, value.replace(/(?!^)\+/, '').replace(/[^0-9+]/g, ''));
                        // const inputEvent = new Event('input', { bubbles: true });
                        let inputEvent;
                        if (typeof Event === 'function') {
                            inputEvent = new Event('input');
                        }
                        else {
                            inputEvent = document.createEvent('Event');
                            inputEvent.initEvent('input', true, true);
                        }
                        ele.dispatchEvent(inputEvent);
                    }
                    catch (ex) {
                        // do nothing
                    }
                }, 
                // @ts-ignore
                onPhoneNumberBlur: (status, value, countryData, phoneNumber) => {
                    if (value === '') {
                        rest.onChange('');
                    }
                    else {
                        rest.onChange(phoneNumber.replace(/(?!^)\+/, '').replace(/[^0-9+]/g, ''));
                    }
                    store.dispatch(formActions.change(`${rest.name}Country`, countryData.iso2));
                }, "data-private": true }),
            inlineIndicator,
            optionalRightLabel));
    }
    else {
        return (React.createElement(ErrorBoundary, null,
            React.createElement(TextField, Object.assign({}, rest, { maxlength: rest.maxLength, value: rest.value || '', inlineIndicator: inlineIndicator, onKeyDown: (e) => {
                    if (rest.type === 'email') {
                        const isSpace = e && (e.which === 32 || e.keyCode === 32) ? true : false;
                        if (isSpace) {
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                    else {
                        return true;
                    }
                }, onBlur: (e) => {
                    const ele = e.target;
                    const newVal = (ele.value || '').toString();
                    rest.onChange(newVal.toString().trim());
                }, onChange: (val) => {
                    const newVal = val || '';
                    rest.onChange(newVal.toString().trimLeft());
                }, "data-private": true }))));
    }
};
const MDTextFieldComponent = ({ id, type = 'text', model, debounce, label = '', ariaLabel = '', placeholder = '', required = false, errorMessagesShow = 'touched', maxLength, rows, validateOn = 'blur', showValidationTickIcon = false, autoFocus = false, spellCheck = false, integerPattern = false, otherProps, otherControlProps, otherValidators, onChange, rightLabel = '', t, isPhone, onPaste, disabled }) => {
    const controlProps = Object.assign({ onChange: (value) => {
            store.dispatch(formActions.setTouched(model));
            if (onChange) {
                onChange(value || '');
            }
        }, onPaste: (e) => {
            store.dispatch(formActions.setTouched(model));
            if (onPaste) {
                onPaste(e);
            }
        }, spellCheck,
        isPhone, pattern: integerPattern ? '[0-9]*' : undefined, withFieldValue: showValidationTickIcon, rightLabel,
        disabled }, otherControlProps);
    if (rows) {
        controlProps.rows = rows;
    }
    return (React.createElement("div", { id: `${id}-wrapper`, className: `md-textfield-wrapper ${isPhone ? 'md-textfield-wrapper--phone' : ''}` },
        React.createElement(Control.text, Object.assign({ id: id, type: type, model: model, debounce: debounce, label: label, "aria-label": ariaLabel, placeholder: placeholder, required: required, component: CustomTextField, autoFocus: autoFocus, validators: Object.assign({ valueMissing: (val) => !required || (val && val.trim().length > 0), maxLength: (val) => !maxLength || !val || val.length <= maxLength, emailInvalid: (val) => type !== 'email' || !val || val.length === 0 || isEmail(val), phoneInvalid: (val) => !isPhone || !val || val.length === 0 || isPhoneNumber(val) || val.indexOf('+64') !== -1, phoneInvalidNZ: (val) => !isPhone || !val || val.length === 0 || isPhoneNumber(val) || val.indexOf('+64') === -1, 
                // this is set in customerdetails.tsx
                duplicateEmail: () => true }, otherValidators), showValidationTickIcon: showValidationTickIcon, controlProps: controlProps }, otherProps, { maxLength: maxLength, validateOn: validateOn, "data-private": true })),
        React.createElement(Errors, { className: "error-container", model: model, messages: {
                valueMissing: t('errors.requiredField'),
                maxLength: t('errors.maxLength', { maxLength }),
                emailInvalid: t('errors.email'),
                phoneInvalid: t('errors.phone'),
                phoneInvalidNZ: t('errors.phoneNZ'),
                duplicateEmail: t('errors.duplicateEmail')
            }, show: errorMessagesShow })));
};
export const MDTextField = translate(['base'])(MDTextFieldComponent);
