import * as React from 'react';
import AccessibleFakeButton from 'react-md/lib/Buttons/Button';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
export const ToggleButton = (props) => {
    return (React.createElement(AccessibleFakeButton, { id: props.id, primary: true, flat: true, "aria-describedby": props.ariaDescribedBy, onClick: () => {
            // send through value if passed in, otherwise the opposite of selected
            const newValue = props.value === null || props.value === undefined ? !props.selected : props.value;
            props.onClick(newValue);
        }, disabled: props.disabled, className: 'md-btn--outline md-btn--outline-primary md-radiobutton ' +
            (props.selected ? 'md-btn--outline-primary--active ' : '') +
            (props.icon ? 'md-btn--outline--icon custom-icon ' + props.icon + ' ' : '') +
            (props.inlineIcon ? 'md-btn--outline--inline-icon' : '') +
            (props.disabled ? 'md-btn--outline--disabled' : '') +
            (props.selected && props.icon ? 'active' : '') },
        React.createElement("div", { className: "md-btn--outline-text" },
            React.createElement("div", null, props.label),
            props.extraLabel && React.createElement("div", null, props.extraLabel)),
        props.inlineIcon && React.createElement(FontIcon, { className: "inline-icon" }, props.inlineIcon)));
};
