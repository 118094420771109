import { isEmail, isEmpty } from '../utilities';
export const getCommonState = (state) => {
    return state.myForms.common;
};
export const showOwnedByBusinessOrTrust = (commonState) => {
    return commonState.uniqueCode && commonState.uniqueCode.length === 6 ? true : false;
};
export const showBusinessName = (commonState) => {
    if (showOwnedByBusinessOrTrust(commonState) && commonState.ownedByBusinessOrTrust === true) {
        return true;
    }
    else {
        return false;
    }
};
export const showCustomerName = (commonState) => {
    if (showOwnedByBusinessOrTrust(commonState) && commonState.ownedByBusinessOrTrust === false) {
        return true;
    }
    else {
        return false;
    }
};
export const showCustomerEmail = (commonState) => {
    if (showBusinessName(commonState) && isEmpty(commonState.businessName) === false) {
        return true;
    }
    else if (showCustomerName(commonState)) {
        return commonState.customerName.firstName && commonState.customerName.lastName ? true : false;
    }
    else {
        return false;
    }
};
export const showBankAccountName = (commonState) => {
    if (showCustomerEmail(commonState)) {
        if (commonState.email) {
            return isEmail(commonState.email);
        }
        else {
            return true;
        }
    }
    return false;
};
export const showBankAccountNumber = (commonState) => {
    if (showBankAccountName(commonState)) {
        const bankAccountName = commonState.payment.paymentBankAccount.bankAccountName;
        const nameIsValid = bankAccountName && bankAccountName.length > 0 && bankAccountName.length <= 80;
        return nameIsValid;
    }
    return false;
};
export const formIsValid = (commonState) => {
    if (showBankAccountNumber(commonState)) {
        const paymentState = commonState.payment;
        // formatted is only available once the bank account has been validated via API
        const { formatted } = paymentState.paymentBankAccount.bankAccountNumber;
        const accountIsValid = formatted && formatted.length === 17;
        return accountIsValid ? true : false;
    }
    else {
        return false;
    }
};
export const isTower = () => {
    return process.env.brand === 'twr';
};
