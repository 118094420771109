import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { rootReducer } from './rootReducer';
import history from './history';
const nonTypedWindow = window;
const composeEnhancers = nonTypedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [
    routerMiddleware(history),
    reduxThunk,
];
export const createTowerStore = (initialData = undefined, historyInstance) => {
    return createStore(connectRouter(historyInstance)(persistedReducer), // new root reducer with router state
    initialData, composeEnhancers(applyMiddleware(...middlewares)));
};
const store = createTowerStore(undefined, history);
export const persistor = persistStore(store);
export default store;
