/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
import { __awaiter } from "tslib";
import * as msRest from 'ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import { ReCaptchaApiContext } from './reCaptchaApiContext';
const WebResource = msRest.WebResource;
class ReCaptchaApi extends ReCaptchaApiContext {
    /**
     * @class
     * Initializes a new instance of the ReCaptchaApi class.
     * @constructor
     *
     * @param {string} [baseUri] - The base URI of the service.
     *
     * @param {object} [options] - The parameter options
     *
     * @param {Array} [options.filters] - Filters to be added to the request pipeline
     *
     * @param {object} [options.requestOptions] - The request options. Detailed info can be found at
     * {@link https://github.github.io/fetch/#Request Options doc}
     *
     * @param {boolean} [options.noRetryPolicy] - If set to true, turn off default retry policy
     *
     */
    constructor(baseUri, options) {
        super(baseUri, options);
        this.serializer = new msRest.Serializer(Mappers);
    }
    // methods on the client.
    /**
     * @summary Verifies a user's response to a reCAPTCHA challenge.
     *
     * @param {VerifyRequest} request
     *
     * @param {XBrandId} xBrandId Possible values include: 'TWR', 'TMI', 'TSB', 'AIR'
     *
     * @param {RequestOptionsBase} [options] Optional Parameters.
     *
     * @returns {Promise} A promise is returned
     *
     * @resolve {HttpOperationResponse} The deserialized result object.
     *
     * @reject {Error|ServiceError} The error object.
     */
    verifyWithHttpOperationResponse(request, xBrandId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Create HTTP transport objects
            const httpRequest = new WebResource();
            let operationRes;
            try {
                const operationArguments = msRest.createOperationArguments({
                    request,
                    xBrandId
                }, options);
                operationRes = yield this.sendOperationRequest(httpRequest, operationArguments, {
                    httpMethod: 'POST',
                    baseUrl: this.baseUri,
                    path: 'recaptcha/v1/verify',
                    headerParameters: [
                        {
                            parameterPath: 'xBrandId',
                            mapper: {
                                required: true,
                                serializedName: 'x-brand-id',
                                type: {
                                    name: 'String'
                                }
                            }
                        }
                    ],
                    requestBody: {
                        parameterPath: 'request',
                        mapper: Object.assign(Object.assign({}, Mappers.VerifyRequest), { required: true })
                    },
                    contentType: 'application/json-patch+json; charset=utf-8',
                    responses: {
                        200: {
                            bodyMapper: Mappers.VerifyResponse
                        },
                        default: {}
                    },
                    serializer: this.serializer
                });
                // Deserialize Response
                let statusCode = operationRes.status;
                if (statusCode === 200) {
                    let parsedResponse = operationRes.parsedBody;
                    try {
                        if (parsedResponse != undefined) {
                            const resultMapper = Mappers.VerifyResponse;
                            operationRes.parsedBody = this.serializer.deserialize(resultMapper, parsedResponse, 'operationRes.parsedBody');
                        }
                    }
                    catch (error) {
                        let deserializationError = new msRest.RestError(`Error ${error} occurred in deserializing the responseBody - ${operationRes.bodyAsText}`);
                        deserializationError.request = msRest.stripRequest(httpRequest);
                        deserializationError.response = msRest.stripResponse(operationRes);
                        return Promise.reject(deserializationError);
                    }
                }
            }
            catch (err) {
                return Promise.reject(err);
            }
            return Promise.resolve(operationRes);
        });
    }
    verify(request, xBrandId, options, callback) {
        if (!callback && typeof options === 'function') {
            callback = options;
            options = undefined;
        }
        let cb = callback;
        if (!callback) {
            return this.verifyWithHttpOperationResponse(request, xBrandId, options)
                .then((operationRes) => {
                return Promise.resolve(operationRes.parsedBody);
            })
                .catch((err) => {
                return Promise.reject(err);
            });
        }
        else {
            msRest.promiseToCallback(this.verifyWithHttpOperationResponse(request, xBrandId, options))((err, data) => {
                if (err) {
                    return cb(err);
                }
                let result = data.parsedBody;
                return cb(err, result, data.request, data);
            });
        }
    }
}
export { ReCaptchaApi, Models as ReCaptchaApiModels, Mappers as ReCaptchaApiMappers };
