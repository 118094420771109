// import models
import { PaymentApiModels } from './autorest/Payment/paymentApi';
export const XBrandId = PaymentApiModels.XBrandId[process.env.brand.toUpperCase()];
// export to make available elsewhere
export { PaymentApiModels };
export const modelPath = 'myForms.common';
export const actionTypes = {
    LOADING: 'LOADING',
    ERROR: 'ERROR'
};
export const getDefaultCommonState = () => {
    const modal = {
        loading: false,
        error: false,
        uniqueCode: '',
        customerName: {
            firstName: null,
            lastName: null
        },
        ownedByBusinessOrTrust: null,
        businessName: null,
        email: null,
        payment: {
            paymentBankAccount: {
                bankAccountName: null,
                bankAccountNumber: { bank: null, account: null, branch: null, formatted: null, suffix: null },
                bankName: null
            }
        }
    };
    return modal;
};
