import { __awaiter } from "tslib";
import * as React from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import { translate } from 'react-i18next';
import { recaptchaService } from '~/common/state/services';
import { XBrandId } from '~/common/state/constants';
class ReCaptchaComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            dialogVisible: false
        };
    }
    componentDidMount() {
        this.verify();
    }
    render() {
        return (React.createElement(DialogContainer, { id: "recaptchaDialog", portal: true, visible: this.state.dialogVisible, width: 400, closeOnEsc: false, focusOnMount: false, className: "recaptcha-dialog", "aria-label": "Google Recaptcha Dialog", onHide: () => { } },
            React.createElement("div", { className: "recaptcha-dialog", style: { padding: '15px' } },
                React.createElement("h5", null, "Sorry, there was a problem."),
                React.createElement("br", null),
                React.createElement("p", null,
                    "Please try again later, or contact us on",
                    ' ',
                    React.createElement("a", { href: `tel:${process.env.brandPhone}`, id: "phone-link" }, process.env.brandPhone)))));
    }
    verify() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // @ts-ignore
                const token = yield this.props.googleReCaptchaProps.executeRecaptcha(this.props.action);
                const result = yield recaptchaService.verify({ response: token }, XBrandId);
                if (result.success && result.score < this.props.minimumScore) {
                    this.setState({ dialogVisible: true });
                    document.querySelector('#maincontent > .container').remove();
                }
            }
            catch (ex) {
                console.log('recaptcha error', ex);
            }
        });
    }
}
export const ReCaptchaComp = withGoogleReCaptcha(ReCaptchaComponent);
export const ReCaptcha = translate(['base'])(ReCaptchaComp);
