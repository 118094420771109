/**
 * UMD Module which defines our keys for all our different scripts
 * Typically based on enviroment (e.g. dev1 to prod)
 * Also sometimes based on whitelabel (twr, tmi, tsb, air)
 * Written as UMD so webpack and typescript can utilize
 */

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define([], factory(root));
  } else if (typeof exports === 'object') {
    module.exports = factory(root);
  } else {
    root.myPlugin = factory(root);
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function (root) {
  'use strict';
  var brand = process.env.brand || 'twr';
  var myPlugin = {
    // webpack.config
    getBrandFullName: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      switch (brand) {
        case 'twr':
          return 'Tower Insurance';
        case 'tmi':
          return 'Trade Me Insurance';
        case 'tsb':
          return 'Tower Insurance through TSB';
        case 'air':
          return 'Tower Insurance';
        default:
          return 'Tower Insurance';
      }
    },
    // webpack.config
    getBrandPhone: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      switch (brand) {
        case 'twr':
          return '0800 379 372';
        case 'tmi':
          return '0800 950 145';
        case 'tsb':
          return '0800 950 141';
        case 'air':
          return '0800 950 140';
        default:
          return '';
      }
    },
    // webpack.config
    getBrandPhoneQ2BError: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      switch (brand) {
        case 'twr':
          return '0800 370 068';
        case 'tmi':
          return '0800 950 145';
        case 'tsb':
          return '0800 950 141';
        case 'air':
          return '0800 950 140';
        default:
          return '';
      }
    },
    // webpack.config
    getRenewalsPhone: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      switch (brand) {
        case 'twr':
          return '0800 992 922';
        case 'tmi':
          return '0800 950 145';
        case 'tsb':
          return '0800 950 141';
        case 'air':
          return '0800 950 140';
        default:
          return '';
      }
    },
    // index.html
    getSite247Key: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      return '21c63dc1d27e401a0f9949d462e585b6';
    },
    // index.html
    getZopimKey: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      var key = '4ftz5AUQDYqu6OuEGgWHm3tbGL2P09r0';
      if (brand === 'tmi') {
        key = '2eQzxrwJr9x5bs9OOjQtdIAafKQJFPAL';
      }
      return key;
    },
    // index.html
    getGtmKey: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      switch (brand) {
        case 'twr':
          return 'GTM-5DHJJ2';
        case 'tmi':
          return 'GTM-TCKHHRH';
        case 'tsb':
          return 'GTM-KPKBGB2';
        case 'air':
          return 'GTM-5DHJJ2';
        default:
          return 'GTM-5DHJJ2';
      }
    },
    // src/common/state
    getHotJarKey: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      return '1298719';
    },
    // src/root/store
    getLogRocketKey: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      return '18eji9/twr';
    },
    // src/index
    getReCaptchaKey: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      const keys = {
        twr: {
          default: '6Leqf7EUAAAAANyhLbuWh-y6Ixw6wbpLk0Nt4w11',
          preprod: '6Ld1vrYUAAAAAKvvuPowSmlHrHQ1PwOeArdyl2zA',
          prod: '6Ld9vrYUAAAAALsspkeg_PCRh2FdAguxsVL7Mfnj'
        },
        tmi: {
          default: '6Leha9gUAAAAANtNqLs_Oo0DfJHsYVonBf7EzyjB',
          preprod: '6Ld_1NkUAAAAADXin7jvyhFiouMg6nJp3OjMyEy8',
          prod: '6Le4_9cUAAAAAKdQ_UP4WFkVYxzBc1tr_0iVbwU4'
        },
        tsb: {
          default: '6Ldy29oUAAAAANvad8D6lYw50zYgrCB2mr-7xk53',
          preprod: '6LfP29oUAAAAAJi7qfso53hNRFsOtqGaESDP25Oh',
          prod: '6LdYh9sUAAAAACzYuIyLOcrpzFJ2dWwkrXbpq4SG'
        },
        air: {
          default: '6LfKvuEUAAAAAGrv1Ex3y84gGoaxm3EW6AdHa1jK',
          preprod: '6Lf4vuEUAAAAAB8beLt6RHr5A9QX7szVRCviJ1c5',
          prod: '6LdPv-EUAAAAAKA8aiZbZoPhM5VwSR7OJIKzvQqY'
        }
      };
      var enviroment = 'default';
      if (this._isPreProd() || this._isPlt()) {
        enviroment = 'preprod';
      } else if (this._isProd()) {
        enviroment = 'prod';
      }
      return keys[brand] && keys[brand][enviroment] ? keys[brand][enviroment] : '';
    },
    // Portal and Claims
    getRootCMSUrl: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      if (brand === 'twr') {
        if (this._isDev()) {
          return 'https://cmsuat.tower.co.nz';
        } else {
          return 'https://www.tower.co.nz';
        }
      } else if (brand === 'tmi') {
        if (this._isDev()) {
          return 'https://service-twrtst1.testdmz.co.nz';
        } else {
          return 'https://service.trademeinsurance.co.nz/account/Login?loginRedirectUrl=%2Fmy-insurance';
        }
      } else {
        return '';
      }
    },
    // Auth0
    getAuth0Config: function (overrideBrand) {
      var brand = overrideBrand ? overrideBrand : process.env.brand || 'twr';
      var host = '';
      var env = '';

      try {
        host = window.location.hostname;
      } catch (ex) {
        // do nothing
      }

      if (process.env.AUTH0_TENANT) {
        env = process.env.AUTH0_TENANT;
      } else if (host === 'localhost') { // wont be needed for dynamic pipelines as will have to specity process.env.AUTH0_TENANT
        env = process.env.API_ENV || 'dev1';
      } else if (host.indexOf('-plt.') > -1) {
        env = 'plt';
      } else if (this._isPreProd()) {
        env = 'preprod';
      } else if (this._isProd()) {
        env = 'prod';
      } else {
        env = host.substring(host.indexOf('-') + 1, host.indexOf('.'));
      }

      var keys = {
        twr: {
          dev1: {
            domain: 'login-twr-dev1.towercloud.co.nz',
            clientID: 'KGahA9LqqK2cstwM2pONGXsrvvBhFEm8',
            audience: 'https://twr-dev1.portal.digital.tower.co.nz/'
          },
          dev2: {
            domain: 'login-twr-dev2.towercloud.co.nz',
            clientID: 'iEfYzVG2wMa6RMhK4rPG27DGneHw9FDe',
            audience: 'https://twr-dev2.portal.digital.tower.co.nz/'
          },
          dev3: {
            domain: 'login-twr-dev3.towercloud.co.nz',
            clientID: '0ag6LBZdd3zUS979Irmlpar46w1X2jDF',
            audience: 'https://twr-dev3.portal.digital.tower.co.nz/'
          },
          int: {
            domain: 'login-twr-int.towercloud.co.nz',
            clientID: 'eZbXuvMtiFioOjjY7VR4gV68vXKfOHeF',
            audience: 'https://twr-int.portal.digital.tower.co.nz/'
          },
          plat: {
            domain: 'login-twr-plat.towercloud.co.nz',
            clientID: 'goGeRoSaRUuhPbKoSPNQFrXIJOBClwi9',
            audience: 'https://twr-plat.portal.digital.tower.co.nz/'
          },
          sit: {
            domain: 'login-twr-sit.towercloud.co.nz',
            clientID: 'Eo3C79dD79mOVeUPOUhfH0IubSVfCwvD',
            audience: 'https://twr-sit.portal.digital.tower.co.nz/'
          },
          sit02: {
            domain: 'login-twr-sit02.towercloud.co.nz',
            clientID: 'Jk718oM4y1Tb3g0rpuUs0XXi157d6xuQ',
            audience: 'https://twr-sit02.portal.digital.tower.co.nz/'
          },
          sit03: {
            domain: 'login-twr-sit03.towercloud.co.nz',
            clientID: 'oAHnAFQN3sc0qn7FxzK48MxQi44yLcen',
            audience: 'https://twr-sit03.portal.digital.tower.co.nz/'
          },
          uat: {
            domain: 'login-twr-uat.towercloud.co.nz',
            clientID: 'A4tGvT1TC5UnawhEuunk07eKVVkGdscP',
            audience: 'https://twr-uat.portal.digital.tower.co.nz/'
          },
          plt: {
            domain: 'login-plt.towercloud.co.nz',
            clientID: 'advBmSlmbXpBGzjk7U4oX3MUP6MAha15',
            audience: 'https://twr-plt.portal.digital.tower.co.nz/'
          },
          preprod: {
            domain: 'login-preprod.tower.co.nz',
            clientID: 'OmWcEpq6v6bzevFECqMBfzC3c9IjflH6',
            audience: 'https://twr-preprod.portal.digital.tower.co.nz/'
          },
          prod: {
            domain: 'login.tower.co.nz',
            clientID: 'LsWTF2lA6Cc4ac93BLcxE0CH2TImMpcw',
            audience: 'https://twr.portal.digital.tower.co.nz/'
          }
        },
        tmi: {
          dev1: {
            domain: 'login-tmi-dev1.towercloud.co.nz',
            clientID: 'ZtXylZjIXmDsTwsNINjjgkgdWmpxRr7D',
            audience: 'https://tmi-dev1.portal.digital.tower.co.nz/'
          },
          dev2: {
            domain: 'login-tmi-dev2.towercloud.co.nz',
            clientID: 'XQmxnUpTAc5vhXFQnG5SxpcNI2lUB2D9',
            audience: 'https://tmi-dev2.portal.digital.tower.co.nz/'
          },
          dev3: {
            domain: 'login-tmi-dev3.towercloud.co.nz',
            clientID: 'r48ywU6qymBDArDcG80iWE7edMZxcs5S',
            audience: 'https://tmi-dev3.portal.digital.tower.co.nz/'
          },
          int: {
            domain: 'login-tmi-int.towercloud.co.nz',
            clientID: '29jfSxSzM3aqK3xO2wjDrDEqJfyjlRsO',
            audience: 'https://tmi-int.portal.digital.tower.co.nz/'
          },
          sit: {
            domain: 'login-tmi-sit.towercloud.co.nz',
            clientID: '9RwYeq4iP5zNzfcWHUzV9R42kId71gB9',
            audience: 'https://tmi-sit.portal.digital.tower.co.nz/'
          },
          plat: {
            domain: 'login-tmi-plat.towercloud.co.nz',
            clientID: 'QfYreFloaoZsSmI8rn2cBKdWNsAthI5s',
            audience: 'https://tmi-plat.portal.digital.tower.co.nz/'
          },
          sit02: {
            domain: 'login-tmi-sit02.towercloud.co.nz',
            clientID: 'TK4ENdPiCUvhAO2QY0PiS8Ze4dSM8MJj',
            audience: 'https://tmi-sit02.portal.digital.tower.co.nz/'
          },
          sit03: {
            domain: 'login-tmi-sit03.towercloud.co.nz',
            clientID: 'fUQ905CuEDZYz1KMiw0f7FvZz8Y2Bf8e',
            audience: 'https://tmi-sit03.portal.digital.tower.co.nz/'
          },
          uat: {
            domain: 'login-tmi-uat.towercloud.co.nz',
            clientID: 'xDiIEZ3XXrcDXmEVVM1gPVJpz4qQNApZ',
            audience: 'https://tmi-uat.portal.digital.tower.co.nz/'
          },
          plt: {
            domain: 'login-plt.towercloud.co.nz',
            clientID: 'buKWzxr6kfDye7B3n2ZFCFiFtvDO7kq6',
            audience: 'https://tmi-plt.portal.digital.tower.co.nz/'
          },
          preprod: {
            domain: 'login-tmi-preprod.towercloud.co.nz',
            clientID: '3qeUfjNKgRdRPDbWs43L3U8nc5qcJP3f',
            audience: 'https://tmi-preprod.portal.digital.tower.co.nz/'
          },
          prod: {
            domain: 'login.trademeinsurance.co.nz',
            clientID: 'Xkq9TvLSMpHc121zrVQe650x4Sko0QNM',
            audience: 'https://tmi.portal.digital.tower.co.nz/'
          }
        },
        tsb: {
          dev1: {
            domain: 'login-tsb-dev1.towercloud.co.nz',
            clientID: 'nq7bIOmERlJwEoWX0ylVZ3mBNRzFEq2i',
            audience: 'https://tsb-dev1.portal.digital.tower.co.nz/'
          },
          dev2: {
            domain: 'login-tsb-dev2.towercloud.co.nz',
            clientID: 'HZ2098PsWV5W02MYMGQ8Q04g5jRVD1bH',
            audience: 'https://tsb-dev2.portal.digital.tower.co.nz/'
          }
        }
      };
      var output = { domain: '', clientID: '', audience: '', connection: 'ui-db-connection' };
      if (keys[brand] && keys[brand][env]) {
        output.domain = keys[brand][env].domain;
        output.clientID = keys[brand][env].clientID;
        output.audience = keys[brand][env].audience;
      }
      return output;
    },
    // INTERNAL
    _isDev: function () {
      return !this._isPreProd() && !this._isPlt() && !this._isProd();
    },
    // INTERNAL
    _isPlt: function () {
      try {
        var host = window.location.hostname || '';
        return host.indexOf('-plt.') > -1;
      } catch (ex) {
        return false;
      }
    },
    // INTERNAL
    _isPreProd: function () {
      try {
        var host = window.location.hostname || '';
        return host.indexOf('-preprod.') > -1;
      } catch (ex) {
        return false;
      }
    },
    // INTERNAL
    _isProd: function () {
      try {
        var host = window.location.hostname || '';
        var prodKeys = ['my.', 'portal.', 'insurance.', 'airnz.', 'refund.'];
        prodKeys.push(brand + '.towercloud.co.nz');
        prodKeys.push(brand + '.tower.co.nz');
        var isProd = false;
        prodKeys.forEach(function (key) {
          if (host.indexOf(key) > -1) {
            isProd = true;
          }
        });
        return isProd;
      } catch (ex) {
        return false;
      }
    }
  };
  return myPlugin;
});
