/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
export const VerifyRequest = {
    serializedName: 'VerifyRequest',
    type: {
        name: 'Composite',
        className: 'VerifyRequest',
        modelProperties: {
            response: {
                required: true,
                serializedName: 'response',
                type: {
                    name: 'String'
                }
            },
            remoteIp: {
                serializedName: 'remoteIp',
                type: {
                    name: 'String'
                }
            }
        }
    }
};
export const VerifyResponse = {
    serializedName: 'VerifyResponse',
    type: {
        name: 'Composite',
        className: 'VerifyResponse',
        modelProperties: {
            success: {
                serializedName: 'success',
                type: {
                    name: 'Boolean'
                }
            },
            score: {
                serializedName: 'score',
                type: {
                    name: 'Number'
                }
            }
        }
    }
};
