import * as React from 'react';
import { Header, Footer } from '~/common/components';
import './Core.scss';
export const CoreLayout = (props) => {
    return (React.createElement("div", { id: props.id, className: "Layout-Core" },
        React.createElement(Header, { headerText: '' }),
        React.createElement("main", { id: "maincontent", className: "main", role: "main" },
            React.createElement("div", { className: "container md-grid" }, props.children)),
        React.createElement(Footer, null)));
};
export default CoreLayout;
