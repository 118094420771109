import * as React from 'react';
import { connectWithLifecycle } from 'react-lifecycle-component';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import history from 'root/history';
import { translate } from 'react-i18next';
import { GenericErrorView, RefundPageView } from './views';
import './styles/index.scss';
const AppRoutes = () => {
    return (React.createElement(Route, { render: ({ location }) => (React.createElement(Switch, { key: location.key, location: location },
            React.createElement(Route, { path: "/", component: RefundPageView }),
            React.createElement(Route, { component: GenericErrorView }))) }));
};
export const AppComponent = () => (React.createElement(ConnectedRouter, { history: history },
    React.createElement(AppRoutes, null)));
export const App = connectWithLifecycle()(translate(['base'])(AppComponent));
