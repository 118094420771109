import * as React from 'react';
import { translate } from 'react-i18next';
import { HeaderMobileMenu } from '~/common/components';
import MarketingHeader from './MarketingHeader';
import './Header.scss';
export class HeaderComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showLoginDialog: false
        };
    }
    render() {
        const { headerText } = this.props;
        return (React.createElement("header", { className: "header-main" },
            React.createElement("div", { className: "header-content" },
                process.env.brand === 'tsb' && (React.createElement(React.Fragment, null,
                    React.createElement("a", { href: "https://www.tsb.co.nz", className: "logo logo-tsb no-border", title: "TSB Insurance" },
                        React.createElement("span", { className: "visually-hidden" }, "TSB")),
                    React.createElement("span", { className: "logo-spacer" }, "\u00A0"))),
                (process.env.brand === 'tsb' || process.env.brand === 'twr' || process.env.brand === 'air') && (React.createElement("a", { href: "https://www.tower.co.nz", className: "logo no-border", title: "Tower Insurance" },
                    React.createElement("span", { className: "visually-hidden" }, "Tower Insurance"))),
                process.env.brand === 'tmi' && (React.createElement("a", { href: "https://www.trademeinsurance.co.nz", className: "logo no-border", title: "Trademe Insurance" },
                    React.createElement("span", { className: "visually-hidden" }, "Trademe Insurance"))),
                headerText && React.createElement("h1", { className: `md-title ` }, headerText),
                React.createElement(MarketingHeader, null),
                React.createElement("div", { className: "header-links" },
                    React.createElement(HeaderMobileMenu, null)))));
    }
}
export const Header = translate(['base'])(HeaderComponent);
