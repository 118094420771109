import * as i18next from 'i18next';
// @ts-ignore
import * as Backend from 'i18next-chained-backend';
// @ts-ignore
import * as LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import * as XHR from 'i18next-xhr-backend';
const brand = (process.env && process.env.brand) || 'twr';
// todo: add multi variate in again
// const variant = (process.env && process.env.variant) || '';
// const lang = `${brand}${variant}`;
// const isDev = process.env.NODE_ENV === 'development';
const backendConfig = {
    backends: [LocalStorageBackend, XHR],
    backendOptions: [
        {
            prefix: 'i18next_res_',
            expirationTime: 1 * 24 * 60 * 60 * 1000
        },
        {
            loadPath: (lngs, namespace) => {
                return '/locales/' + lngs + '/' + namespace + '.json';
            },
            parse: (data) => {
                return JSON.parse(data);
            }
        }
    ]
};
// if (isDev) {
backendConfig.backends.splice(0, 1);
backendConfig.backendOptions.splice(0, 1);
// }
const i18n = i18next.use(Backend).init({
    backend: backendConfig,
    defaultNS: 'base',
    ns: ['base'],
    fallbackLng: 'en',
    lng: `${brand}`,
    debug: false,
    interpolation: {
        defaultVariables: {
            __brandFullName__: process.env.brandFullName,
            __brandPhone__: process.env.brandPhone,
            __brandPhoneQ2BError__: process.env.brandPhoneQ2BError,
            __renewalsPhone__: process.env.renewalsPhone
        }
    },
    react: {
        wait: true
    }
});
// if (variant) {
//   import(`./${lang}.json`).then((module) => {
//     const langResource = module.default;
//     // @ts-ignore
//     // https://www.i18next.com/overview/api#addresourcebundle
//     i18n.default.addResourceBundle(brand, 'translations', langResource.translations);
//     i18n.changeLanguage(brand);
//   });
// }
export default i18n;
