import * as React from 'react';
import FontIcon from 'react-md/lib/FontIcons';
import { translate } from 'react-i18next';
import './MarketingHeader.scss';
import { selectors as commonSelectors } from '~/common/state';
const theTarget = '_blank';
export class MarketingHeaderComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            productsMenuOpen: false
        };
    }
    render() {
        return (React.createElement("div", { id: 'marketing-header' },
            this.renderNav(),
            this.renderContact()));
    }
    renderNav() {
        const { productsMenuOpen } = this.state;
        return (React.createElement("nav", { id: 'marketing-header-menu' },
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("a", { onClick: () => this.setState({ productsMenuOpen: !productsMenuOpen }), className: 'has-children' },
                        "Products",
                        React.createElement(FontIcon, { className: 'arrow-down' }, productsMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down')),
                    React.createElement("ul", { className: 'sub-menu' },
                        React.createElement("li", null,
                            React.createElement("a", { href: this.props.t('base:header.carInsurance'), target: theTarget, className: 'car' },
                                React.createElement("span", null, "Car"))),
                        React.createElement("li", null,
                            React.createElement("a", { href: this.props.t('base:header.houseInsurance'), target: theTarget, className: 'house' },
                                React.createElement("span", null, "House "))),
                        React.createElement("li", null,
                            React.createElement("a", { href: this.props.t('base:header.contentsInsurance'), target: theTarget, className: 'contents' },
                                React.createElement("span", null, "Contents"))),
                        commonSelectors.isTower() && (React.createElement("li", null,
                            React.createElement("a", { href: this.props.t('base:header.travelInsurance'), target: theTarget, className: 'travel' },
                                React.createElement("span", null, "Travel")))),
                        commonSelectors.isTower() && (React.createElement("li", null,
                            React.createElement("a", { href: this.props.t('base:header.businessInsurance'), target: theTarget, className: 'business' },
                                React.createElement("span", null, "Business")))),
                        commonSelectors.isTower() && (React.createElement("li", null,
                            React.createElement("a", { href: this.props.t('base:header.allInsurance'), target: theTarget, className: 'all' },
                                React.createElement("span", null, "See all")))))),
                commonSelectors.isTower() && (React.createElement("li", null,
                    React.createElement("a", { href: this.props.t('base:header.claims'), target: theTarget }, "Claims"))),
                commonSelectors.isTower() && (React.createElement("li", null,
                    React.createElement("a", { href: this.props.t('base:header.manage'), target: theTarget }, "Manage"))),
                React.createElement("li", null,
                    React.createElement("a", { href: this.props.t('base:header.aboutUs'), target: theTarget }, "About us")))));
    }
    renderContact() {
        return (React.createElement("div", { id: 'marketing-header-links' },
            React.createElement("a", { href: this.props.t('base:header.contactUsLink'), id: 'contact-us-link', target: theTarget }, this.props.t('header.contactUs')),
            commonSelectors.isTower() && (React.createElement("a", { href: `tel:${process.env.brandPhone}`, id: 'phone-link', "data-testid": 'phone-link', target: theTarget }, process.env.brandPhone))));
    }
}
export const MarketingHeader = translate(['base'])(MarketingHeaderComponent);
export default MarketingHeader;
