import * as React from 'react';
import { isNumber } from 'util';
export const getQueryStringParameters = (url) => {
    return getUrlParameters(url);
};
export const getHashStringParameters = (url) => {
    return getUrlParameters(url, true);
};
const getUrlParameters = (url, hashParam) => {
    let query = '';
    const paramStartChar = hashParam ? '#' : '?';
    if (url) {
        if (url.split(paramStartChar).length > 0) {
            query = url.split(paramStartChar)[1];
        }
    }
    else if (hashParam) {
        query = window.location.hash.substring(1);
    }
    else {
        query = window.location.search.substring(1);
    }
    return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
    }, {});
};
export const getKeyFromQueryString = (uri) => {
    const params = uri.indexOf('?') >= 0 ? getQueryStringParameters(uri) : [];
    return params && params['key'] ? params['key'] : null;
};
export const isEmail = (email) => {
    return (email &&
        email.length < 256 &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
};
export const isPhoneNumber = (phone) => {
    const realPhone = (phone || '').replace(/ /g, '').replace('+', '00');
    if (realPhone.indexOf('0064') > -1) {
        return realPhone.length >= 12 && realPhone.length <= 14 && realPhone.match(/[^0-9+]+/) === null;
    }
    else {
        return realPhone.length >= 7 && realPhone.length <= 20 && realPhone.match(/[^0-9+ ]+/) === null;
    }
};
// The IntlTelInput component handles phone number formatting usually, but if we have to format a NZ phone number
// (e.g. in the edit-profile page), we put a space after the country code and use +64 rather than 0064.
export const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith('0064')) {
        return '+64 ' + phoneNumber.substring(4);
    }
    else if (phoneNumber.startsWith('+64')) {
        return '+64 ' + phoneNumber.substring(3);
    }
    return phoneNumber;
};
export const isEmpty = (value) => {
    return value == null || value === undefined || (value.length === 0 && JSON.stringify(value) !== '{}');
};
export const isEmptyObj = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
};
export const nameValid = (name) => {
    return isEmpty(name) === false && name.length <= 40 && name.length > 0;
};
export const enumToAutocomplete = (data) => {
    return Object.entries(data).map((item) => {
        return { value: item[1], label: item[0].split(/(?=[A-Z])/).join(' ') };
    });
};
export const isOldIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    return msie > 0 || trident > 0;
};
export const addWorkdays = (date, days) => {
    const increment = days / Math.abs(days);
    const newDate = date.clone().add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
    let remaining = days % 5;
    while (remaining !== 0) {
        newDate.add(increment, 'days');
        if (newDate.isoWeekday() !== 6 && newDate.isoWeekday() !== 7) {
            remaining -= increment;
        }
    }
    return newDate;
};
export const currencyFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2,
});
export const formatPrice = (price, asHtml, colorSymbol, twoDp) => {
    if (price === null || price === undefined || isNumber(price) === false) {
        return '';
    }
    const result = currencyFormatter.format(price);
    if (!asHtml) {
        return !twoDp ? result.toString().split('.')[0] : result;
    }
    else {
        const amount = result.split('$')[1];
        return (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "price" },
                React.createElement("span", { className: colorSymbol ? 'symbol' : '' }, "$"),
                !twoDp ? amount.toString().split('.')[0] : amount)));
    }
};
export const joinClassNames = (...classNames) => {
    return classNames.join(' ');
};
export const capitalize = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};
export const capitalizeFirstLetterOnly = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};
export const lowerCaseFirstCharOnly = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toLowerCase() + s.slice(1);
};
export const checkYesNoNull = (v) => {
    switch (v) {
        case true:
            return 'Yes';
        case false:
            return 'No';
        default:
            return '';
    }
};
export const checkYesNoUnsure = (v) => {
    switch (v) {
        case 'NO':
            return 'No';
        case 'YES':
            return 'Yes';
        case 'UNSURE':
            return 'Unsure';
        default:
            return '';
    }
};
export const handlePaste = (e) => {
    let clipboardData;
    let pastedData;
    // Get pasted data via clipboard API
    // @ts-ignore
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');
    // Do whatever with pasteddata
    return pastedData || '';
};
export const getValFromObjByDelimiteredString = (delimStr, obj) => {
    if (delimStr && obj) {
        try {
            return delimStr.split('.').reduce((a, b) => {
                if (b.indexOf('[') > -1) {
                    const splitPath = b.split('[');
                    const actualPath = splitPath[0];
                    const index = Number(splitPath[1].replace(']', ''));
                    return a[actualPath][index];
                }
                else {
                    return a[b];
                }
            }, obj);
        }
        catch (ex) {
            return null;
        }
    }
    else {
        return null;
    }
};
export const isNotEmptyObj = (obj, ...skipKeys) => {
    return Object.keys(obj)
        .filter((k) => !skipKeys.includes(k))
        .some((k) => (Array.isArray(obj[k]) ? obj[k].length > 0 : !!obj[k]));
};
export const getTenant = (hostname, API_ENV, brand) => {
    let tenant = '';
    if (hostname === 'localhost') {
        tenant = brand + '-' + API_ENV;
    }
    else if (hostname.indexOf('-plt.') > -1) {
        tenant = `${brand}-plt`;
    }
    else if (hostname.indexOf('-preprod.') > -1) {
        tenant = `${brand}-preprod`;
    }
    else if (hostname.indexOf('my.') > -1 ||
        hostname.indexOf('portal.') > -1 ||
        hostname.indexOf('insurance.') > -1 ||
        hostname.indexOf('airnz.') > -1 ||
        hostname.indexOf(`${brand}.towercloud.co.nz`) > -1) {
        tenant = `${brand}-prod`;
    }
    else if (hostname.indexOf('-') > 0) {
        // Assume subdomain has the form $BRAND-$ENV, e.g. twr-int.towercloud.co.nz.
        const env = hostname.substring(hostname.indexOf('-') + 1, hostname.indexOf('.'));
        tenant = brand + '-' + env;
    }
    return tenant;
};
export const isProd = (hostname, API_ENV, brand) => {
    const tenant = getTenant(hostname, API_ENV, brand);
    return tenant.indexOf('prod') > -1;
};
export const isPreProd = (hostname, API_ENV, brand) => {
    const tenant = getTenant(hostname, API_ENV, brand);
    return tenant.indexOf('preprod') > -1;
};
export const formatBankAccountNumber = (bank, branch, account, suffix) => {
    return `${bank}${branch}${account}${(suffix === null || suffix === void 0 ? void 0 : suffix.length) > 1 ? suffix.padStart(4, '0') : suffix}`;
};
