import { __awaiter } from "tslib";
import * as React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { translate } from 'react-i18next';
import { CoreLayout } from '~/common/layouts';
import { selectors, modelPath, actions } from '~/common/state';
import { Question, MDTextField, PaymentBankAccountName, PaymentBankAccountNumber, FormFooter, FormMessage, ReCaptcha, MDRadioButton, } from '~/common/components';
import store from '~/root/store';
import './RefundPage.scss';
const mapStateToProps = (state) => ({
    commonState: selectors.getCommonState(state),
});
export class RefundPageComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            formLoading: false,
            formError: null,
            showSuccess: false,
        };
    }
    render() {
        const { commonState, t } = this.props;
        const { formLoading, formError, showSuccess } = this.state;
        return (React.createElement(CoreLayout, { id: "Covid" },
            React.createElement("div", { className: "heading-wrapper" },
                React.createElement("h2", { className: "page-heading2" }, t('base:pageTitle')),
                React.createElement("a", { href: t('base:learnMore.link'), className: "custom-button" }, t('base:learnMore.text'))),
            React.createElement("div", { className: "md-cell md-cell--12" },
                React.createElement(Form, { model: modelPath, hideNativeErrors: true, validateOn: "change", className: "covid-form" }, showSuccess ? (React.createElement("div", { id: "Success" },
                    React.createElement("h2", { className: "page-heading2" }, t('base:successPage.heading')),
                    React.createElement("p", null, "Thanks, your multi-policy discount refund will be refunded to the bank account you\u2019ve provided in the next 5-10 working days."),
                    React.createElement("a", { href: t('base:links.homePage'), id: "btnBackPage", className: "md-btn md-btn--flat md-btn--text md-pointer--hover md-text--theme-primary md-ink--primary md-inline-block md-btn--outline md-btn--outline-tertiary" },
                        React.createElement("label", null,
                            "Back to ",
                            this.props.t('base:logoAltText'))))) : (React.createElement(React.Fragment, null,
                    React.createElement(ReCaptcha, { action: "covid", minimumScore: 0.3 }),
                    React.createElement("h2", { className: "page-heading2" }, t('base:p1.title')),
                    React.createElement("p", null, t('base:p1.copy')),
                    React.createElement("br", null),
                    " ",
                    React.createElement("br", null),
                    React.createElement("h2", { className: "page-heading2" }, t('base:p2.title')),
                    React.createElement("p", { dangerouslySetInnerHTML: { __html: t('base:p2.copy') } }),
                    React.createElement("br", null),
                    " ",
                    React.createElement("br", null),
                    React.createElement(Question, { id: "questionUniqueCode", model: `${modelPath}.uniqueCode`, translation: "base:uniqueCode", noTick: true },
                        React.createElement(MDTextField, { id: "codeField", model: `${modelPath}.uniqueCode`, label: t('base:uniqueCode.title'), ariaLabel: t('base:uniqueCode.title'), maxLength: 6, validateOn: ['blur', 'change'], otherValidators: {
                                codeValid: (val) => val && val.length && val.length === 6,
                            }, onChange: () => {
                                this.setState({ formError: null });
                            } })),
                    selectors.showOwnedByBusinessOrTrust(commonState) && (React.createElement(Question, { id: "questionOwnedByBusinessOrTrust", model: `${modelPath}.ownedByBusinessOrTrust`, translation: "base:ownedByBusinessOrTrust" },
                        React.createElement(MDRadioButton, { id: "ownedByBusinessOrTrust", model: `${modelPath}.ownedByBusinessOrTrust`, options: [
                                { value: true, label: t('button.yes') },
                                { value: false, label: t('button.no') },
                            ], onChange: () => {
                                this.setState({ formError: null });
                            } }))),
                    selectors.showBusinessName(commonState) && (React.createElement(Question, { id: "questionBusinessName", model: `${modelPath}.businessName`, noTick: true, translation: "base:businessName" },
                        React.createElement(MDTextField, { id: "businessName", model: `${modelPath}.businessName`, label: 'Business or trust name', ariaLabel: 'Business or trust name', validateOn: ['blur', 'change'], required: true, onChange: () => {
                                this.setState({ formError: null });
                            } }))),
                    selectors.showCustomerName(commonState) && (React.createElement(Question, { id: "questionCustomerName", model: `${modelPath}.customerName.firstName`, noTick: true, translation: "base:customerName" },
                        React.createElement(MDTextField, { id: "firstNameField", model: `${modelPath}.customerName.firstName`, label: t('base:firstName.title'), ariaLabel: t('base:firstName.title'), validateOn: ['blur', 'change'], required: true, onChange: () => {
                                this.setState({ formError: null });
                            } }),
                        React.createElement(MDTextField, { id: "lastNameField", model: `${modelPath}.customerName.lastName`, label: t('base:lastName.title'), ariaLabel: t('base:lastName.title'), validateOn: ['blur', 'change'], required: true, onChange: () => {
                                this.setState({ formError: null });
                            } }))),
                    selectors.showCustomerEmail(commonState) && (React.createElement(Question, { id: "questionEmail", model: `${modelPath}.email`, translation: "base:email", noTick: true },
                        React.createElement(MDTextField, { id: "emailField", type: "email", model: `${modelPath}.email`, label: t('base:email.title'), ariaLabel: t('base:email.title'), validateOn: ['blur', 'change'], debounce: 250, maxLength: 60, showValidationTickIcon: true, onChange: () => {
                                this.setState({ formError: null });
                            } }))),
                    selectors.showBankAccountName(commonState) && (React.createElement(PaymentBankAccountName, { modelPath: `${modelPath}.payment`, handleOnChange: () => {
                            this.setState({ formError: null });
                        } })),
                    selectors.showBankAccountNumber(commonState) && (React.createElement(PaymentBankAccountNumber, { modelPath: `${modelPath}.payment`, paymentState: commonState.payment, handleOnChange: () => {
                            this.setState({ formError: null });
                        } })),
                    formError && (React.createElement(FormMessage, { id: "ErrorMessage", title: t(`base:uniqueCode.${formError}.title`), description: t(`base:uniqueCode.${formError}.description`), isError: true }))))),
                !showSuccess && selectors.formIsValid(commonState) && (React.createElement(FormFooter, { disabled: formLoading, validating: formLoading, showBackButton: true, backButtonLabel: "Cancel", submitButtonLabel: t('base:button.submit'), handleGoBack: () => {
                        window.location = t('base:links.homePage');
                    }, handleSubmit: () => __awaiter(this, void 0, void 0, function* () {
                        this.setState({ formLoading: true, formError: null });
                        const result = yield actions.submitCode()(store.getState);
                        if (result === 'success') {
                            this.setState({ formLoading: false, formError: null, showSuccess: true });
                            localStorage.clear();
                            sessionStorage.clear();
                        }
                        else {
                            this.setState({ formLoading: false, formError: result, showSuccess: false });
                        }
                    }) })))));
    }
}
export const RefundPageTranslated = translate('base')(RefundPageComponent);
export const RefundPageView = connect(mapStateToProps, null)(RefundPageTranslated);
export default RefundPageView;
