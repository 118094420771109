// autorest apis
import { PaymentApi } from './autorest/Payment/paymentApi';
import { ReCaptchaApi } from './autorest/ReCaptcha/reCaptchaApi';
import { RefundApi } from '~/common/state/autorest/Refund/src';
// used for route paths
const origin = location.origin;
const isDev = process.env.NODE_ENV === 'development';
const API_ENV = process.env.API_ENV;
let BASE_API;
if (isDev) {
    BASE_API = `https://api-${API_ENV}.towercloud.co.nz`;
}
else if (origin.includes('refund.towercloud.co.nz') || origin.includes('tmi-refund.towercloud.co.nz')) {
    BASE_API = 'https://my-api.towercloud.co.nz';
}
else if (origin.includes('refund.tower.co.nz') || origin.includes('refund.trademeinsurance.co.nz')) {
    BASE_API = 'https://my-api.tower.co.nz';
}
else {
    BASE_API = origin.replace(process.env.brand, 'api').replace('-refund', '');
}
// Tragically if we add this as the 'options' param of the service constructor the headers are not picked up,
// so each api call needs pass these options
// See https://github.com/Azure/autorest.typescript/issues/437.
export const defaultRequestOptions = {
    customHeaders: { 'x-origin-app': process.env.brand + '-web' },
};
// create instance of apis
export const paymentService = new PaymentApi(BASE_API, { noRetryPolicy: true });
export const recaptchaService = new ReCaptchaApi(BASE_API, {
    noRetryPolicy: true,
});
export const refundService = new RefundApi({
    baseUri: BASE_API,
    noRetryPolicy: true,
});
export const apiBaseUrl = BASE_API;
