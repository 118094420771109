import { __rest } from "tslib";
import * as React from 'react';
import Drawer from 'react-md/lib/Drawers';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import { translate } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import './HeaderMobileMenu.scss';
const HeaderMobileMenuLink = ({ id, to, text, icon, iconRight, newTab, external, location, disabled, onClick }) => !external && !disabled && !onClick ? (React.createElement(Link, { id: id, to: to, className: to !== '/' && location.pathname === to ? 'active' : undefined, target: newTab ? '_blank' : undefined },
    icon && React.createElement(FontIcon, null, icon),
    React.createElement("span", null, text),
    iconRight && React.createElement(FontIcon, { className: "icon-right" }, iconRight))) : (React.createElement("a", { id: id, href: !disabled ? to : undefined, target: !disabled ? '_blank' : undefined, onClick: onClick },
    icon && React.createElement(FontIcon, null, icon),
    React.createElement("span", null, text),
    iconRight && React.createElement(FontIcon, { className: "icon-right" }, iconRight)));
export class HeaderMobileMenuComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            drawerVisible: false,
            productsVisible: false
        };
        this.setDrawerVisible = (drawerVisible) => this.setState({ drawerVisible });
        this.toggleDrawerVisible = () => this.setState({ drawerVisible: !this.state.drawerVisible });
        this.updateBodyClass = () => {
            const toggleOn = this.state.drawerVisible && !document.body.classList.contains('drawer-open');
            const toggleOff = !this.state.drawerVisible && document.body.classList.contains('drawer-open');
            if (toggleOn || toggleOff) {
                document.body.classList.toggle('drawer-open');
            }
        };
    }
    render() {
        const { drawerVisible } = this.state;
        this.updateBodyClass();
        return (React.createElement(React.Fragment, null,
            React.createElement("a", { id: "mobileMenuButton", href: "javascript:void(0)", onClick: this.toggleDrawerVisible, "aria-expanded": drawerVisible, "aria-haspopup": true, "aria-owns": "mobileMenuDrawer" },
                React.createElement(FontIcon, null, "menu")),
            React.createElement(Drawer, { id: "mobileMenuDrawer", type: Drawer.DrawerTypes.TEMPORARY, visible: drawerVisible, position: "right", onVisibilityChange: this.setDrawerVisible },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("a", { id: "mobileMenuMenuButton", href: "javascript:void(0)", onClick: this.toggleDrawerVisible },
                        React.createElement("span", null, "Menu")),
                    React.createElement("a", { id: "mobileMenuCloseButton", href: "javascript:void(0)", onClick: this.toggleDrawerVisible },
                        React.createElement(FontIcon, null, "close"))),
                React.createElement("hr", { className: "overview-separator" }),
                this.renderMarketingLinks(),
                React.createElement("hr", null))));
    }
    renderMarketingLinks() {
        const _a = this.props, { t } = _a, rest = __rest(_a, ["t"]);
        const { productsVisible } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuProductsLink", to: "/", onClick: (e) => {
                    e.preventDefault();
                    this.setState({ productsVisible: !this.state.productsVisible });
                }, text: "Products", icon: this.state.productsVisible ? 'chevron_left' : '', iconRight: !this.state.productsVisible ? 'chevron_right' : '' }, rest)),
            React.createElement("hr", null),
            productsVisible && (React.createElement(React.Fragment, null,
                React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuCarLink", to: t('base:header.carInsurance'), text: "Car", external: true, newTab: true }, rest)),
                React.createElement("hr", null),
                React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuHouseLink", to: t('base:header.houseInsurance'), text: "House", external: true, newTab: true }, rest)),
                React.createElement("hr", null),
                React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuContentsLink", to: t('base:header.contentsInsurance'), text: "Contents", external: true, newTab: true }, rest)),
                process.env.brand === 'twr' && (React.createElement(React.Fragment, null,
                    React.createElement("hr", null),
                    React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuTravelLink", to: t('base:header.travelInsurance'), text: "Travel", external: true, newTab: true }, rest)))),
                process.env.brand === 'twr' && (React.createElement(React.Fragment, null,
                    React.createElement("hr", null),
                    React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuBusinessLink", to: t('base:header.businessInsurance'), text: "Business", external: true, newTab: true }, rest)))),
                process.env.brand === 'twr' && (React.createElement(React.Fragment, null,
                    React.createElement("hr", null),
                    React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuAllInsuranceLink", to: t('base:header.allInsurance'), text: "All", external: true, newTab: true }, rest)))))),
            !productsVisible && (React.createElement(React.Fragment, null,
                process.env.brand === 'twr' && (React.createElement(React.Fragment, null,
                    React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuClaimsLink", to: t('base:header.claims'), text: "Claim", external: true, newTab: true }, rest)),
                    React.createElement("hr", null))),
                process.env.brand === 'twr' && (React.createElement(React.Fragment, null,
                    React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuManageLink", to: t('base:header.manage'), text: "Manage", external: true, newTab: true }, rest)),
                    React.createElement("hr", null))),
                React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuAboutUsLink", to: t('base:header.aboutUs'), text: "About us", external: true, newTab: true }, rest)),
                React.createElement("hr", null),
                React.createElement(HeaderMobileMenuLink, Object.assign({ id: "mobileMenuContactLink", to: t('base:header.contactUsLink'), text: "Contact us", external: true, newTab: true }, rest))))));
    }
}
export const HeaderMobileMenu = withRouter(translate(['base'])(HeaderMobileMenuComponent));
