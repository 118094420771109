// libraries
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedFlagsProvider } from 'flag';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import 'moment';
import * as moment from 'moment-timezone';
import * as envConfig from '~/envConfig';
// import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
// root
import i18n from './root/i18n';
import store, { persistor } from './root/store';
// app
import { App } from './common/App';
moment.tz.setDefault('Pacific/Auckland');
// render
const rootEl = document.getElementById('root');
const render = (Component) => ReactDOM.render(React.createElement(GoogleReCaptchaProvider, { reCaptchaKey: envConfig.getReCaptchaKey() },
    React.createElement(I18nextProvider, { i18n: i18n },
        React.createElement(Provider, { store: store },
            React.createElement(PersistGate, { persistor: persistor },
                React.createElement(ConnectedFlagsProvider, null,
                    React.createElement(AppContainer, null,
                        React.createElement(Component, null))))))), rootEl);
render(App);
// hot reload
if (module.hot) {
    module.hot.accept('./common/App', () => {
        return render(App);
    });
}
