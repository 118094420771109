import * as React from 'react';
import { translate } from 'react-i18next';
import { CoreLayout } from '~/common/layouts';
import './GenericError.scss';
export const GenericErrorComponent = ({ errorText, t }) => {
    return (React.createElement(CoreLayout, { id: "genericError" },
        React.createElement("div", null, errorText ? React.createElement("h5", null, errorText) : React.createElement("h5", null, errorText ? errorText : t('errors.default')))));
};
export const GenericErrorView = translate('base')(GenericErrorComponent);
export default GenericErrorView;
